import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import UserStore from '../../store/UserStore';
import AppStore from '../../store/AppStore';
import SVG from 'react-inlinesvg';
import IconLoader from '../../assets/icon/icon-loading.svg';
import IconEye from '../../assets/icon/icon-eye.svg';
import IconArrow from '../../assets/icon/icon-arrow-right.svg';
import IconPlus from '../../assets/icon/icon-plus.svg';
import IconAngle from '../../assets/icon/icon-checked.svg';
import { useTranslation } from 'react-i18next';

import { toast } from 'react-toastify';
import useRouter from '../../hooks/useRouter';
import Routes from '../../routes';

const SingUpForm = () => {
  const { t } = useTranslation();

  const router = useRouter();

  const errorsValues = { email: '', password: '', confirmPassword: '' };

  const [formValues, setFormValues] = useState(() => ({
    email: '',
    password: '',
    confirmPassword: '',
  }));

  /*  const clientIdGoogle = process.env.REACT_APP_CLIENT_ID_AUTH_GOOGLE || '';

  const clientIdMicrosoft = process.env.REACT_APP_CLIENT_ID_AUTH_MICROSOFT || '';

  const consumerKeyTwitter = process.env.REACT_APP_CONSUMER_KEY_TWITTER || '';

  const consumerSecretTwitter = process.env.REACT_APP_CONSUMER_SECRET_TWITTER || ''; */

  const [showLoader, setShowLoader] = useState(false);
  /* const [showLoaderSocial, setShowLoaderSocial] = useState({
    google: false,
    microsoft: false,
  });
  const [showAuthButtonSoc, setShowAuthButtonSoc] = useState(true); */
  const [formErrors, setFormErrors] = useState(() => errorsValues);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [privacyError, setPrivacyError] = useState(false);
  const [statusRegistration, setStatusRegistration] = useState(AppStore.isRegisterPage);
  const [buttonLocked, setButtonLocked] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  /* const [dataMicrosoft, setDataMicrosoft] = useState(null); */

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasErrors = false;
    if (statusRegistration && !privacyAccepted) {
      hasErrors = true;
      setPrivacyError(true);
    }
    if (!validate(formValues)) {
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    setButtonLocked(true);
    if (formValues.email === '' || formValues.password === '') {
      validate(formValues);
      setButtonLocked(false);
      return;
    }
    setShowLoader(true);
    const { email, password } = formValues;

    const userData = {
      email,
      password,
      firstName: '',
      middleName: '',
      lastName: '',
      timezone: '',
    };
    if (!statusRegistration) {
      try {
        await UserStore.logIn(userData);
        handleEffectAfterValidate();
        router.push(Routes.home);
      } catch (error) {
        handleEffectAfterValidate();
        if (error.code === 401) {
          toast.error(t('loginError'));
        } else {
          toast.error(t('somethingWentWrong'));
        }
      }
    } else {
      try {
        await UserStore.registrationUser(userData);
        handleEffectAfterValidate();
        router.push(Routes.home);
      } catch (error) {
        handleEffectAfterValidate();
        if (error.message === 'User duplicate.') {
          toast.error(t('regError'));
        } else {
          toast.error(t('somethingWentWrong'));
        }
      }
    }
  };

  const handleChange = (type, event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => {
      const localValues = { ...prevState, [name]: value };
      return { ...localValues };
    });
    setFormErrors((prevState) => {
      return { ...prevState, [name]: '' };
    });
  };

  const validate = (values) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (values.email === '') {
      errorsValues.email = `${t('authorization.errorsEmptyInput')}`;
    } else if (!regex.test(values.email)) {
      errorsValues.email = `${t('authorization.noValidEmail')}`;
    }
    if (values.password === '') {
      errorsValues.password = `${t('authorization.errorsEmptyInput')}`;
    } else if (values.password.length < 3) {
      errorsValues.password = `${t('authorization.noValidPassword')}`;
    }
    if (statusRegistration) {
      if (values.confirmPassword === '') {
        errorsValues.confirmPassword = `${t('authorization.errorsEmptyInput')}`;
      } else if (values.confirmPassword.length < 3) {
        errorsValues.confirmPassword = `${t('authorization.noValidPassword')}`;
      } else if (values.confirmPassword !== values.password) {
        errorsValues.confirmPassword = `${t('authorization.coincidePassword')}`;
      }
    }
    const foundErrorsValues = Object.keys(errorsValues).find((key) => errorsValues[key] !== '');

    setFormErrors(errorsValues);
    return !foundErrorsValues;
  };

  const classErrorForm = (error) => {
    return error !== '' ? 'no-valid-input' : '';
  };

  const switchAuth = () => {
    clearFormInput();
    setStatusRegistration(!statusRegistration);
  };

  const clearFormInput = () => {
    setFormValues({
      email: '',
      password: '',
      confirmPassword: '',
    });
  };

  const handleEffectAfterValidate = () => {
    setButtonLocked(false);
    setShowLoader(false);
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleConfirmPassword = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  /*  const responseGoogle = (res) => {
    clearFormInput();
    setButtonLocked(true);
    setShowLoaderSocial((prevState) => {
      return { ...prevState, google: true };
    });
    UserStore.logInSocial({ token: res.accessToken, name: 'google' })
      .then(() => {
        handleEffectAfterValidate();
        router.push(Routes.home);
      })
      .catch((error) => {
        setShowLoaderSocial((prevState) => {
          return { ...prevState, google: false };
        });
        handleEffectAfterValidate();
        if (error.code === 401) {
          toast.error(`Ошибка входа: неверный логин или пароль`);
        } else {
          toast.error(`Ошибка входа: ${error.message}`);
        }
      });
  };
  const responseGoogleErr = (res) => {
    if (res) {
      setShowAuthButtonSoc(false);
    }
  };
  const signInTwitter = (err, data) => {
    console.log(err, data, 'Twitter');
  };
  const signInMicrosoft = (err, res) => {
    // TODO: вызывается много раз, сделал костыль
    if (dataMicrosoft === null) {
      if (res) {
        setDataMicrosoft(res);
        clearFormInput();
        setButtonLocked(true);
        setShowLoaderSocial((prevState) => {
          return { ...prevState, microsoft: true };
        });
        UserStore.logInSocial({ token: res.accessToken, name: 'microsoft' })
          .then(() => {
            handleEffectAfterValidate();
            router.push(Routes.home);
          })
          .catch((error) => {
            setShowLoaderSocial((prevState) => {
              return { ...prevState, microsoft: false };
            });
            handleEffectAfterValidate();
            if (error.code === 401) {
              toast.error(`Ошибка входа: неверный логин или пароль`);
            } else {
              toast.error(`Ошибка входа: ${error.message}`);
            }
          });
      } else if (err) {
        toast.error(`Ошибка входа: ${err}`);
      }
    }
  }; */

  const privacyHandle = () => {
    setPrivacyError(false);
    setPrivacyAccepted((prevState) => !prevState);
  };

  return (
    <form
      className={`sign-up-page__form ${statusRegistration ? 'register' : ''}`}
      onSubmit={handleSubmit}
    >
      <h2>
        {statusRegistration ? `${t('authorization.register')}:` : `${t('authorization.login')}:`}
      </h2>
      <div className="sign-up-page__form_wrapper-field">
        <div className="sign-up-page__form-field">
          <input
            type="text"
            placeholder={`${t('authorization.email')}...`}
            name="email"
            value={formValues.email}
            onChange={(event) => handleChange('email', event)}
            className={`input sign-up-page__form-input
                 ${classErrorForm(formErrors.email)}`}
          />
          {formErrors.email && <div className="input_error">{formErrors.email}</div>}
        </div>
        <div className="sign-up-page__form-field">
          <input
            type={passwordShown ? 'text' : 'password'}
            placeholder={`${t('authorization.password')}...`}
            name="password"
            value={formValues.password}
            onChange={(event) => handleChange('password', event)}
            className={`input sign-up-page__form-input sign-up-page__form-input_with-icon
                 ${classErrorForm(formErrors.password)}`}
          />
          <span className="sign-up-page__form-show-pass" onClick={togglePassword}>
            <SVG src={IconEye} width={20} />
          </span>
          {formErrors.password && <div className="input_error">{formErrors.password}</div>}
        </div>
        {statusRegistration && (
          <>
            <div className="sign-up-page__form-field">
              <input
                type={confirmPasswordShown ? 'text' : 'password'}
                placeholder={`${t('authorization.confirmPassword')}...`}
                name="confirmPassword"
                value={formValues.confirmPassword}
                onChange={(event) => handleChange('confirmPassword', event)}
                className={`input sign-up-page__form-input
                 ${classErrorForm(formErrors.confirmPassword)}`}
              />
              <span className="sign-up-page__form-show-pass" onClick={toggleConfirmPassword}>
                <SVG src={IconEye} width={20} />
              </span>
              {formErrors.confirmPassword && (
                <div className="input_error">{formErrors.confirmPassword}</div>
              )}
            </div>

            <div className="sign-up-page__privacy-container">
              <div
                className={`sign-up-page__privacy-mark ${
                  privacyError ? 'sign-up-page__privacy-mark_error' : ''
                }`}
                onClick={privacyHandle}
              >
                <span>{privacyAccepted && <SVG src={IconAngle} width={16} />}</span>
              </div>
              <div
                className={`sign-up-page__privacy-text ${
                  privacyError ? 'sign-up-page__privacy-text_error' : ''
                }`}
                dangerouslySetInnerHTML={{
                  __html: t('acceptPolicy').replace(
                    '%privacy%',
                    `<a href=${Routes.policy} target="_blank" rel="noreferrer">
                      ${t('footer.policy')}
                    </a>`,
                  ),
                }}
              />
            </div>
          </>
        )}
      </div>
      <div className={`sign-up-page__form_wrapper-button ${buttonLocked ? 'locked' : ''}`}>
        <button type="button" className="main-button _switch-auth-btn icon" onClick={switchAuth}>
          {statusRegistration ? t('authorization.backToLogin') : t('authorization.register')}
          {statusRegistration ? (
            <span className="icon-arrow-left">
              <SVG src={IconArrow} width={10} />
            </span>
          ) : (
            <span className="icon-plus">
              <SVG src={IconPlus} width={13} />
            </span>
          )}
        </button>

        <button
          type="submit"
          className={`main-button icon  button-accent ${showLoader ? 'loading' : ''}`}
        >
          {statusRegistration ? t('authorization.completeRegistration') : t('authorization.login')}
          <SVG className="loading" src={IconLoader} width={20} />
          <span className="icon-arrow-right">
            <SVG src={IconArrow} width={10} />
          </span>
        </button>
      </div>

      {/* {!statusRegistration && showAuthButtonSoc && (
        <div className="sign-up-page__form_social-button">
          <h3>Or sign in with social account:</h3>
          <TwitterLogin
            authCallback={signInTwitter}
            consumerKey={consumerKeyTwitter}
            consumerSecret={consumerSecretTwitter}
            className="isHidden"
            children={
              <NavLink exact to={Routes.login} className="main-button icon _twitter">
                <SVG src={IconTwitter} width={20} />
                Sign in with Twitter
                <span className="icon-arrow-right">
                  <SVG src={IconArrow} width={10} />
                </span>
              </NavLink>
            }
          />
          <GoogleLogin
            clientId={clientIdGoogle}
            render={(renderProps) => (
              <button
                type="button"
                onClick={renderProps.onClick}
                className={`main-button icon _google ${showLoaderSocial.google ? 'loading' : ''}`}
              >
                <SVG src={IconGoogle} width={20} />
                Sign in with Google
                {showLoaderSocial.google ? (
                  <SVG className="loading" src={IconLoader} width={20} />
                ) : (
                  <span className="icon-arrow-right">
                    <SVG src={IconArrow} width={10} />
                  </span>
                )}
              </button>
            )}
            onSuccess={responseGoogle}
            onFailure={responseGoogleErr}
            cookiePolicy="single_host_origin"
          />
          <MicrosoftLogin
            clientId={clientIdMicrosoft}
            authCallback={signInMicrosoft}
            graphScopes={['openid', 'profile', 'email', 'offline_access']}
            postLogoutRedirectUri={Routes.login}
            children={
              <button
                type="button"
                className={`main-button icon _windows ${
                  showLoaderSocial.microsoft ? 'loading' : ''
                }`}
              >
                <SVG src={IconWindow} width={20} />
                Login with Windows Hello
                {showLoaderSocial.microsoft ? (
                  <SVG className="loading" src={IconLoader} width={20} />
                ) : (
                  <span className="icon-arrow-right">
                    <SVG src={IconArrow} width={10} />
                  </span>
                )}
              </button>
            }
          />
        </div>
      )} */}

      {/* возможно понадобится/*}

      {/* {!statusRegistration ? ( /*}
      {/*  <div className={`sign-up-page__login-reg ${buttonLocked ? 'locked' : ''}`}> */}
      {/*    <button type="button">{t('authorization.forgotPassword')}</button> */}
      {/*    <button type="button" onClick={toRegistration}> */}
      {/*      {t('authorization.registration')} */}
      {/*    </button> */}
      {/*  </div> */}
      {/* ) : ( */}
      {/*  <div */}
      {/*    className={`sign-up-page__login-reg ${statusRegistration ? 'login' : ''} ${ */}
      {/*      buttonLocked ? 'locked' : '' */}
      {/*    }`} */}
      {/*  > */}
      {/*    <span>{t('authorization.haveAccount')}</span> */}
      {/*    <button type="button" onClick={toLogin}> */}
      {/*      {t('authorization.login')} */}
      {/*    </button> */}
      {/*  </div> */}
      {/* )} */}
    </form>
  );
};
export default observer(SingUpForm);
