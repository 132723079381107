import axios from 'axios';
import { toast } from 'react-toastify';
import i18n from 'i18next';
import UserStore from '../store/UserStore';

export const apiInstance = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const instance = axios.create(defaultOptions);

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401 && error.response.data.message === 'Invalid JWT Token') {
        toast.error(i18n.t('authorizationError'));
        UserStore.logOut();
      }
      throw error;
    },
  );

  instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
  });

  return instance;
};

export const shortLinkApiInstance = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_SHORT_LINK_API_ENDPOINT,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const instance = axios.create(defaultOptions);
  instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
  });

  return instance;
};
