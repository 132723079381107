import { apiInstance } from '../api';

export const getCurrency = async () => {
  try {
    const getCurrencies = await apiInstance().get(`/currencies `);
    return getCurrencies.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
