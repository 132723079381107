import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import UserStore from '../../store/UserStore';
import Spinner from '../../components/Base/Spinner';
import { useTranslation } from 'react-i18next';

const BalanceTable = ({ date }) => {
  const { t } = useTranslation();
  const [hasNextPage, setHasNextPage] = useState(true);
  const [loading, setLoading] = useState(false);
  const [balanceData, setBalanceData] = useState([]);
  const [page, setPage] = useState(1);
  const [balanceDataAll, setBalanceDataAll] = useState(balanceData);
  const [lastBalanceValue, setLastBalanceValue] = useState(null);
  const [statQueryDate, setStatQueryDate] = useState({
    partNumber: 1,
    partSize: 10,
    from: '',
    to: '',
  });

  const getBalanceUserQuery = async (date) => {
    try {
      const balance = await UserStore.getStatisticUser(date);
      UserStore.userBalanceLoad = true;
      setBalanceData(balance);
      if (balance.length !== 0) {
        setBalanceDataAll((prevBalanceData) => [...prevBalanceData, ...balance]);
        if (!lastBalanceValue) {
          const lastNewBalance = Number(balance[0].newUserBalance.amount);
          setLastBalanceValue(lastNewBalance);
          lastNewBalance !== Number(UserStore.user.balance) && (await UserStore.getUser());
        }
      } else {
        setHasNextPage(false);
      }
    } catch (e) {
      UserStore.userBalanceLoad = false;
    } finally {
      setLoading(false);
    }
  };

  const setFormatDate = (date) => {
    const zeroPad = (input, length) => {
      return (Array(length + 1).join('0') + input).slice(-length);
    };
    if (date) {
      const year = new Date(date).getFullYear();
      const month = new Date(date).getMonth() + 1;
      const day = new Date(date).getDate();
      return `${year}-${zeroPad(month, 2)}-${zeroPad(day, 2)}`;
    }
    return '';
  };

  const handleLoadMore = () => {
    setLoading(true);
    setPage(page + 1);
    setStatQueryDate((prevState) => {
      const localValues = {
        ...prevState,
        partNumber: page + 1,
      };
      return { ...localValues };
    });
    setHasNextPage(true);
  };
  const [infiniteRef] = useInfiniteScroll({
    loading,
    onLoadMore: handleLoadMore,
    hasNextPage,
  });

  useEffect(() => {
    if (date !== null) {
      setBalanceDataAll([]);
      setPage(1);
      setHasNextPage(true);
      setStatQueryDate((prevState) => {
        const localValues = {
          ...prevState,
          partNumber: 1,
          from: date.filter !== 'allDate' ? setFormatDate(date.dateStart) : '',
          to: date.filter !== 'allDate' ? setFormatDate(date.dateEnd) : '',
        };
        return { ...localValues };
      });
    }
  }, [date]);

  useEffect(() => {
    getBalanceUserQuery(statQueryDate);
  }, [statQueryDate.partNumber]);

  return (
    <div className="balance-page__table-wrapper">
      <table className="balance-page__table table">
        <thead>
          <tr>
            <th>{t('date')}</th>
            <th>{t('change')}</th>
            <th>{t('Balance')}</th>
            <th>{t('payer')}</th>
            <th>{t('productList')}</th>
          </tr>
        </thead>
        <tbody>
          {balanceDataAll.map((el, i) => {
            const createdAtSplit = el.createdAt.split(' ');
            return (
              <tr key={`${i}${el.id}`}>
                <td>
                  {createdAtSplit[0]}
                  <span className="balance-page__table-time"> {createdAtSplit[1]}</span>
                </td>
                <td className="plus"> + {`${el.money.amount} ${el.money.currency.isoCode}`}</td>
                <td className="bold">
                  {`${el.newUserBalance.amount} ${el.newUserBalance.currency.isoCode}`}
                </td>
                <td>{el.payerNickname ? el.payerNickname : '-'}</td>
                <td className="balance-page__table-product-list">
                  {el.lineItems?.length &&
                    el.lineItems.map((product) => {
                      return <div key={product}>{product}</div>;
                    })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {balanceDataAll.length === 0 && !hasNextPage && (
        <div className="balance-page__table-not-found">{t('nothingFound')}</div>
      )}
      {hasNextPage && (
        <div ref={infiniteRef} className="loader">
          <Spinner width={24} height={24} />
        </div>
      )}
    </div>
  );
};
export default observer(BalanceTable);
