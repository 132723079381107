import React from 'react';
import { useTranslation } from 'react-i18next';

const WithdrawTableItem = ({ withdrawData }) => {
  const { t } = useTranslation();
  const createdAtSplit = withdrawData.createdAt.split(' ');
  return (
    <tr className="withdraw-table-item" key={withdrawData.id}>
      <td>
        {createdAtSplit[0]}
        <span className="withdraw-table-item__time"> {createdAtSplit[1]}</span>
      </td>
      <td className="plus">
        {withdrawData.money?.amount} {withdrawData?.money?.currency?.isoCode}
      </td>
      <td>
        <span
          className={`withdraw-table-item__status withdraw-table-item__status_${withdrawData.status}`}
        >
          {t(withdrawData.status)}
        </span>
      </td>
    </tr>
  );
};

export default WithdrawTableItem;
