import React, { useState } from 'react';
import SVG from 'react-inlinesvg';
import IconCopyLink from '../../assets/icon/icon-copy-link.svg';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { createShortLink } from '../../api/actions/short-link';
import Spinner from '../Base/Spinner';
import colorsVars from '../../utils/colorsVars';
import UserStore from '../../store/UserStore';

const GetShortLinkButton = ({ defaultLink, siteId }) => {
  const { t } = useTranslation();
  const [showLink, setShowLink] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shortLinkValue, setShortLinkValue] = useState('');

  const copyTextToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success(t('linkCopied'), {
      position: 'top-center',
    });
  };

  const getShortLinkForFan = async (e) => {
    e.stopPropagation();
    if (UserStore.isUserNoBlankInfo) {
      toast.error(t('cannotEarn'));
      return;
    }
    setLoading(true);
    const res = await createShortLink(defaultLink, siteId);
    const shortLinkValue = res.target;
    setShortLinkValue(shortLinkValue);
    setShowLink(true);
    setLoading(false);
    setTimeout(() => {
      copyTextToClipboard(shortLinkValue);
    }, 200);
  };
  return (
    <div className="short-link">
      {showLink ? (
        <div
          className="short-link__container"
          onClick={() => {
            copyTextToClipboard(shortLinkValue);
          }}
        >
          <SVG src={IconCopyLink} width={12} />
          <span>{shortLinkValue}</span>
        </div>
      ) : (
        <button
          onClick={getShortLinkForFan}
          className={`short-link__get-btn ${loading ? 'short-link__get-btn_loading' : ''}`}
        >
          <span>{t('getLink')}</span>
          {loading && (
            <div className="short-link__btn-loader">
              <Spinner width={18} height={18} borderWidth={2} color={colorsVars.colorLight} />
            </div>
          )}
        </button>
      )}
    </div>
  );
};

export default GetShortLinkButton;
