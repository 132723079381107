import { action, computed, makeObservable, observable } from 'mobx';
import { loginUser, registrationUser, authSocialAccounts } from '../api/actions/authorization';
import {
  getUser,
  editUser,
  uploadAvatarUser,
  addAddressUser,
  editAddressUser,
  checkingBlankUserInfoData,
  userStatistic,
  addNicknameUser,
  editNicknameUser,
  getNicknameUser,
} from '../api/actions/user';
import AppStore from './AppStore';

class UserStore {
  user = {
    id: '',
    roles: [],
    email: '',
    firstName: '',
    middleName: '',
    lastName: '',
    timezone: '',
    balance: '',
    currency: '',
    avatar: '',
  };
  usersContacts = {
    id: '',
    city: '',
    address: '',
    phone: '',
    countryCode: '',
    countryName: '',
  };
  isUserNoBlankInfo = false;
  userInit = false;
  userBalanceLoad = false;
  nicknameInfo = [
    {
      id: '',
      keyword: '',
    },
  ];
  authSocialName = '';

  constructor() {
    makeObservable(this, {
      user: observable,
      usersContacts: observable,
      logIn: action,
      registrationUser: action,
      getUserId: computed,
      userInit: observable,
      getUser: action,
      editUserData: action,
      initProfile: action,
      setUserBalance: action,
      userCheckBlankInfo: action,
      isUserNoBlankInfo: observable,
      userBalanceLoad: observable,
      nicknameInfo: observable,
      getNickname: action,
      addNickname: action,
      editNickname: action,
      logInSocial: action,
      authSocialName: observable,
    });
  }

  get getUserId() {
    return localStorage.getItem('userId');
  }

  initProfile = (infoUser) => {
    this.user.id = infoUser.id;
    this.user.roles = infoUser.roles;
    this.user.email = infoUser.email;
    this.user.firstName = infoUser.firstName || '';
    this.user.middleName = infoUser.middleName || '';
    this.user.lastName = infoUser.lastName || '';
    this.user.timezone = infoUser.timezone || '';
    this.user.balance = infoUser.balance.amount;
    this.user.currency = infoUser.balance.currency.isoCode;
    this.user.avatar = infoUser.avatar || null;
  };

  initContacts = (infoContacts) => {
    const contacts = {
      id: infoContacts.id,
      city: infoContacts.city,
      address: infoContacts.address,
      phone: infoContacts.phone,
      countryCode: infoContacts.country.isoCode,
      countryName: infoContacts.country.name,
    };
    this.usersContacts = { ...contacts };
  };

  setUserBalance = (balance) => {
    this.user.balance = balance;
  };

  logIn = async (userData) => {
    try {
      const loginUserObject = await loginUser(userData.email, userData.password);
      this.authSocialName = '';
      if (loginUserObject.token?.length && loginUserObject.id) {
        localStorage.setItem('token', loginUserObject.token);
        localStorage.setItem('userId', loginUserObject.id);

        await this.getUser(loginUserObject.id).then(() => {
          this.userCheckBlankInfo();
        });
      }
    } catch (e) {
      throw e;
    }

    AppStore.initApp();
  };

  logInSocial = async (userData) => {
    try {
      const loginSocialUserObject = await authSocialAccounts(userData.token, userData.name);
      this.authSocialName = userData.name;
      if (loginSocialUserObject.token?.length && loginSocialUserObject.userSocialAccount.userId) {
        localStorage.setItem('token', loginSocialUserObject.token);
        localStorage.setItem('userId', loginSocialUserObject.userSocialAccount.userId);
        localStorage.setItem('authSocialName', userData.name);
        await this.getUser(loginSocialUserObject.id).then(() => {
          this.userCheckBlankInfo();
        });
      }
    } catch (e) {
      throw e;
    }

    AppStore.initApp();
  };

  logOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    this.nicknameInfo = [
      {
        id: '',
        keyword: '',
      },
    ];
    AppStore.initApp();
  };

  setAuthSocialName = () => {
    this.authSocialName = localStorage.getItem('authSocialName') || '';
  };

  registrationUser = async (userData) => {
    try {
      const user = await registrationUser(userData);
      if (user) {
        const userMainData = { ...user, ...userData };
        this.initProfile(userMainData);
        await this.logIn(userMainData);
      }
    } catch (e) {
      throw e;
    }
  };

  getUser = async (userId) => {
    try {
      const currentUser = await getUser(userId || this.getUserId);

      if (currentUser) {
        this.initProfile(currentUser);
      }
      if (currentUser.keywords?.length) {
        this.nicknameInfo = currentUser.keywords;
      }
      if (currentUser.addresses.length) {
        this.initContacts(currentUser.addresses[0]);
      } else {
        const emptySetContacts = {
          id: '',
          city: '',
          address: '',
          phone: '',
          country: {
            isoCode: '',
            name: '',
          },
        };
        this.initContacts(emptySetContacts);
      }
      this.userInit = true;
    } catch (e) {
      this.logOut();
      throw new Error(e);
    }
  };

  editUserData = async (userData) => {
    try {
      const currentUser = await editUser(this.getUserId, userData);
      if (currentUser) {
        this.initProfile(currentUser);
      }
    } catch (e) {
      throw e;
    }
  };

  uploadAvatarUser = async (userAva) => {
    try {
      const currentUser = await uploadAvatarUser(this.getUserId, userAva);
      if (currentUser) {
        this.initProfile(currentUser);
      }
    } catch (e) {
      throw new Error(e);
    }
  };

  addAddressUser = async (userDataContacts) => {
    try {
      const contactsUser = await addAddressUser(this.getUserId, userDataContacts);
      if (contactsUser) {
        this.initContacts(contactsUser);
      }
    } catch (e) {
      throw new Error(e);
    }
  };

  editAddressUser = async (userDataContacts) => {
    try {
      if (this.usersContacts.id) {
        const contactsUser = await editAddressUser(
          this.getUserId,
          this.usersContacts.id,
          userDataContacts,
        );
        if (contactsUser) {
          this.initContacts(contactsUser);
        }
      }
    } catch (e) {
      throw new Error(e);
    }
  };

  getNickname = async () => {
    try {
      const nickname = await getNicknameUser(this.getUserId);
      if (nickname.length) {
        this.nicknameInfo = [...nickname];
      } else {
        this.nicknameInfo = [
          {
            id: '',
            keyword: '',
          },
        ];
      }
    } catch (e) {
      throw new Error(e);
    }
  };

  addNickname = async (userDataNickname) => {
    try {
      const nickname = await addNicknameUser(this.getUserId, userDataNickname);
      if (nickname) {
        this.nicknameInfo[0] = nickname;
      }
    } catch (e) {
      throw e;
    }
  };

  editNickname = async (userDataNickname) => {
    try {
      const nickname = await editNicknameUser(
        this.getUserId,
        this.nicknameInfo[0].id,
        userDataNickname,
      );
      if (nickname) {
        this.nicknameInfo[0] = nickname;
      } else {
        this.nicknameInfo = [
          {
            id: '',
            keyword: '',
          },
        ];
      }
    } catch (e) {
      throw e;
    }
  };

  userCheckBlankInfo = async () => {
    try {
      const info = await checkingBlankUserInfoData(this.getUserId);
      if (info.length) {
        this.isUserNoBlankInfo = true;
      } else {
        this.isUserNoBlankInfo = false;
      }
    } catch (e) {
      throw new Error(e);
    }
  };

  getStatisticUser = async (userDataStatistic) => {
    try {
      return await userStatistic(this.getUserId, userDataStatistic);
    } catch (e) {
      throw new Error(e);
    }
  };
}

export default new UserStore();
