import React, { useState } from 'react';
import SVG from 'react-inlinesvg';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import UserStore from '../../store/UserStore';
import { toast } from 'react-toastify';

import IconEye from '../../assets/icon/icon-eye.svg';
import IconChecked from '../../assets/icon/icon-checked.svg';
import Spinner from '../../components/Base/Spinner';
import colorsVars from '../../utils/colorsVars';

const ProfileFormPass = () => {
  const { t } = useTranslation();

  const errorsValues = {
    password: '',
    confirmPassword: '',
  };

  const [formValues, setFormValues] = useState(() => ({
    password: '',
    confirmPassword: '',
  }));
  const [showLoader, setShowLoader] = useState(false);
  const [formErrors, setFormErrors] = useState(() => errorsValues);
  const [submitButtonLocked, setSubmitButtonLocked] = useState(true);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setShowLoader(true);

    const hasValidateErrors = validate(formValues);

    if (hasValidateErrors) {
      setShowLoader(false);
      return;
    }

    const profileData = {
      password: formValues.password,
    };
    try {
      await UserStore.editUserData(profileData);
      toast.success(t('dataChangedSuccess'));
      setFormValues((prevState) => {
        const localValues = { ...prevState, confirmPassword: '', password: '' };
        return { ...localValues };
      });
      setSubmitButtonLocked(true);
    } catch (e) {
      toast.error(t('somethingWentWrong'));
    } finally {
      setShowLoader(false);
    }
  };

  const handleChangeInput = (type, event) => {
    const { name, value } = event.target;
    setSubmitButtonLocked(false);
    setFormValues((prevState) => {
      return { ...prevState, [name]: value };
    });
    setFormErrors((prevState) => {
      return { ...prevState, [name]: '' };
    });
  };

  const validate = (values) => {
    if (values.password === '') {
      errorsValues.password = `${t('authorization.errorsEmptyInput')}`;
    } else if (values.password.length < 3) {
      errorsValues.password = `${t('authorization.noValidPassword')}`;
    }
    if (values.confirmPassword === '') {
      errorsValues.confirmPassword = `${t('authorization.errorsEmptyInput')}`;
    } else if (values.confirmPassword.length < 3) {
      errorsValues.confirmPassword = `${t('authorization.noValidPassword')}`;
    } else if (values.confirmPassword !== values.password) {
      errorsValues.confirmPassword = `${t('authorization.coincidePassword')}`;
    }
    const hasValidateErrors = Object.keys(errorsValues).find((key) => errorsValues[key] !== '');

    setFormErrors(errorsValues);

    return hasValidateErrors;
  };

  const classErrorForm = (error) => {
    return error !== '' && error !== null ? 'no-valid-input' : '';
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleConfirmPassword = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  return (
    <form className="profile-page__form" onSubmit={handleSubmit}>
      <h3>{t('userPassword')}</h3>
      <div className="profile-page__form-field-wrapper">
        <div className="profile-page__form-field">
          <label className="label">{t('changePassword')}</label>
          <div className="profile-page__form-pass">
            <input
              type={passwordShown ? 'text' : 'password'}
              placeholder={`${t('authorization.password')}...`}
              name="password"
              autoComplete="new-password"
              value={formValues.password}
              onChange={(event) => handleChangeInput('password', event)}
              className={`input
                 ${classErrorForm(formErrors.password)}`}
            />
            <span className="profile-page__form-show-pass" onClick={togglePassword}>
              <SVG src={IconEye} width={20} />
            </span>
          </div>
          {formErrors.password && <div className="input_error">{formErrors.password}</div>}
        </div>
        <div className="profile-page__form-field">
          <label className="label">{t('authorization.confirmPassword')}</label>
          <div className="profile-page__form-pass">
            <input
              type={confirmPasswordShown ? 'text' : 'password'}
              name="confirmPassword"
              placeholder={`${t('authorization.confirmPassword')}...`}
              value={formValues.confirmPassword}
              onChange={(event) => handleChangeInput('confirmPassword', event)}
              className={`input profile-page__form-input
                 ${classErrorForm(formErrors.confirmPassword)}`}
            />
            <span className="profile-page__form-show-pass" onClick={toggleConfirmPassword}>
              <SVG src={IconEye} width={20} />
            </span>
          </div>
          {formErrors.confirmPassword && (
            <div className="input_error">{formErrors.confirmPassword}</div>
          )}
        </div>
      </div>
      <button
        type="submit"
        disabled={submitButtonLocked || showLoader}
        className={`main-button  button-accent icon ${showLoader ? 'main-button_loading' : ''}`}
      >
        <span>
          <SVG src={IconChecked} width={12} />
          {t('saveChanges')}
        </span>
        {showLoader && (
          <div className="main-button__btn-loader">
            <Spinner width={18} height={18} borderWidth={2} color={colorsVars.colorLight} />
          </div>
        )}
      </button>
    </form>
  );
};
export default observer(ProfileFormPass);
