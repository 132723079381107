import React from 'react';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import UserStore from '../../store/UserStore';
import useRouter from '../../hooks/useRouter';
import Routes from '../../routes';
import CountrySwitcher from '../CountrySwitcher';
import MediaQuery from 'react-responsive';
import Logo from '../Base/Logo';
import SVG from 'react-inlinesvg';
import IconLogout from '../../assets/icon/icon-logout-menu.svg';
import IconQuestion from '../../assets/icon/icon-question.svg';
import breakpoints from '../../utils/breakpoints';
import MobileSelectPage from '../MobileSelectPage/MobileSelectPage';
import HeaderTop from '../HeaderTop';
import { MenuPageList } from '../../utils/MenuPageList';
import { useTranslation } from 'react-i18next';
import { FooterList } from '../../utils/FooterList';

const HeaderSidebar = () => {
  const router = useRouter();
  const menuList = MenuPageList();
  const footerList = FooterList();
  const { t } = useTranslation();

  /* const [msalInstance, onMsalInstanceChange] = useState(); */

  /* const loginHandler = (err, data, msal) => {
    if (!err && data) {
      onMsalInstanceChange(msal);
    }
  }; */
  /* const clientIdGoogle = process.env.REACT_APP_CLIENT_ID_AUTH_GOOGLE || ''; */
  /* const clientIdMicrosoft = process.env.REACT_APP_CLIENT_ID_AUTH_MICROSOFT || ''; */

  /* const { signOut } = useGoogleLogout({
    clientId: clientIdGoogle,
  }); */

  const logOut = () => {
    UserStore.logOut();
    router.push(Routes.login);
    if (UserStore.authSocialName === 'google') {
      localStorage.removeItem('authSocialName');
      /* signOut(); */
    }
  };
  /* const logoutMicrosoft = () => {
    localStorage.removeItem('authSocialName');
    msalInstance.logout();
    UserStore.logOut();
  }; */

  return (
    <div className="header-sidebar">
      <div className="header-sidebar__wrapper-logo">
        <Logo className="header-sidebar__logo" />
        <MediaQuery maxWidth={breakpoints['md-max']}>
          <CountrySwitcher />
        </MediaQuery>
      </div>
      <MediaQuery maxWidth={breakpoints['xs-max']}>
        <HeaderTop />
      </MediaQuery>
      <MediaQuery minWidth={breakpoints['xs-max']}>
        <div className="header-sidebar__menu">
          <div className="header-sidebar__menu-list">
            {menuList.map((menu, i) => {
              return (
                <div className="header-sidebar__menu-item" key={i}>
                  <NavLink
                    exact
                    className="header-sidebar__link"
                    activeClassName="header-sidebar__link_active"
                    to={menu.route}
                  >
                    <SVG src={menu.icon} width={14} />
                    {menu.label}
                  </NavLink>
                </div>
              );
            })}
            <div className="header-sidebar__menu-item">
              <button onClick={logOut} className="header-sidebar__link">
                <SVG src={IconLogout} width={14} />
                {t('menu.logOut')}
              </button>

              {/* TODO:надо переделать покрасивее  
              {msalInstance ? (
                <button onClick={logoutMicrosoft} className="header-sidebar__link">
                  <SVG src={IconLogout} width={14} />
                  {t('menu.logOut')}
                </button>
              ) : (
                <MicrosoftLogin
                  className="isHidden"
                  clientId={clientIdMicrosoft}
                  authCallback={loginHandler}
                />
              )}
              */}
            </div>
          </div>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={breakpoints['xs-max']}>
        <MobileSelectPage logOut={logOut} />
      </MediaQuery>
      <MediaQuery minWidth={breakpoints['xs-max']}>
        <div className="header-sidebar__footer">
          <div className="header-sidebar__footer-menu">
            <div className="header-sidebar__footer-link _year">© 2022.PayFan</div>
            {footerList.secondMenu.map((menu, i) => {
              return (
                <NavLink className="header-sidebar__footer-link" exact to={menu.route} key={i}>
                  {menu.label}
                </NavLink>
              );
            })}
            <NavLink className="header-sidebar__footer-link" exact to="/about">
              <SVG src={IconQuestion} width={20} />
            </NavLink>
          </div>
          <div className="header-sidebar__footer-menu">
            {footerList.firstMenu.map((menu, i) => {
              return (
                <NavLink className="header-sidebar__footer-link" exact to={menu.route} key={i}>
                  {menu.label}
                </NavLink>
              );
            })}
          </div>
        </div>
      </MediaQuery>
    </div>
  );
};

export default observer(HeaderSidebar);
