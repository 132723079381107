import React, { useEffect, useState } from 'react';
import HeaderSidebar from '../HeaderSidebar/HeaderSidebar';
import useRouter from '../../hooks/useRouter';
import Routes from '../../routes';
import FooterMain from '../FooterMain';
import breakpoints from '../../utils/breakpoints';
import MediaQuery from 'react-responsive';

const Layout = ({ children }) => {
  const router = useRouter();
  const [showEl, setShowEl] = useState(true);
  const [fullPageContainer, setFullPageContainer] = useState(false);
  useEffect(() => {
    if (
      router.pathname === Routes.login ||
      router.pathname === Routes.howIsWork ||
      router.pathname === Routes.policy ||
      router.pathname === Routes.feedBackForm ||
      router.pathname === Routes.faq ||
      router.pathname === Routes.contact ||
      router.pathname === Routes.about
    ) {
      setFullPageContainer(true);
      setShowEl(false);
    } else {
      setFullPageContainer(false);
      setShowEl(true);
    }
  }, [router.pathname]);
  return (
    <div className={`main-container-page ${fullPageContainer ? 'full' : ''}`}>
      {showEl && <HeaderSidebar />}
      {children}
      <MediaQuery maxWidth={breakpoints['xs-max']}>{showEl && <FooterMain />}</MediaQuery>
    </div>
  );
};
export default Layout;
