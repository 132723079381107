import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserStore from '../../store/UserStore';
import SVG from 'react-inlinesvg';
import IconAvailableBalance from '../../assets/icon/icon-available-balance.svg';
import Spinner from '../Base/Spinner';
import colorsVars from '../../utils/colorsVars';
import useRouter from '../../hooks/useRouter';
import Routes from '../../routes';
import { createWithdraw } from '../../api/actions/withdrawals';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';

const WithdrawModal = ({ closeModal }) => {
  const { t } = useTranslation();
  const [withdrawalAmount, setWithdrawalAmount] = useState(Number(UserStore.user.balance) || 0);
  const [withdrawalAmountError, setWithdrawalAmountError] = useState(UserStore.user.balance <= 0);
  const [withdrawalSuccess, setWithdrawalSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const router = useRouter();

  const changeHandler = (value) => {
    setWithdrawalAmountError(Number(UserStore.user.balance) < Number(value) || value <= 0);
    setWithdrawalAmount(value);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await createWithdraw(withdrawalAmount);
      UserStore.setUserBalance(res?.user.balance.amount);
      setWithdrawalSuccess(true);
    } catch (e) {
      toast.error('somethingWentWrong');
    } finally {
      setLoading(false);
    }
  };

  const seeStatusClick = () => {
    router.push(Routes.withdraw);
    closeModal();
  };

  return (
    <div className="withdraw-funds">
      <h2>{t('withdrawalOfFounds')}</h2>

      {!withdrawalSuccess ? (
        <>
          <div className="withdraw-funds__balance">
            <SVG className="withdraw-funds__balance-icon" src={IconAvailableBalance} width={14} />
            {t('availableForWithdrawal')}:
            <span>
              {UserStore.user.balance} {UserStore.user.currency}
            </span>
          </div>
          <form onSubmit={submitHandler} className="withdraw-funds__form">
            <div className="withdraw-funds__input-container">
              <div className="withdraw-funds__input-name">
                {t('withdrawalAmount')} ({UserStore.user.currency})
              </div>
              <input
                onChange={(e) => changeHandler(e.target.value)}
                className={`withdraw-funds__input input ${
                  withdrawalAmountError ? 'no-valid-input' : ''
                }`}
                step="0.01"
                min="0"
                lang="en"
                type="number"
                inputMode="decimal"
                name="withdrawalAmount"
                value={withdrawalAmount}
              />
              {withdrawalAmountError && (
                <div className="withdraw-funds__input-error-text">{t('incorrectSum')}</div>
              )}
            </div>
            <button
              disabled={loading || withdrawalAmountError}
              className={`withdraw-funds__button main-button button-accent ${
                loading ? 'main-button_loading' : ''
              }`}
            >
              <span>{t('withdraw')}</span>
              {loading && (
                <div className="main-button__btn-loader">
                  <Spinner width={18} height={18} borderWidth={2} color={colorsVars.colorLight} />
                </div>
              )}
            </button>
          </form>
        </>
      ) : (
        <div className="withdraw-funds__success">
          <div className="withdraw-funds__success-text">{t('withdrawalSuccess')}</div>
          <button
            onClick={seeStatusClick}
            className="withdraw-funds__success-btn  main-button button-accent"
          >
            {t('seeStatus')}
          </button>
        </div>
      )}
    </div>
  );
};

export default observer(WithdrawModal);
