import React from 'react';
import MediaQuery from 'react-responsive';
import breakpoints from '../../../utils/breakpoints';
import { NavLink } from 'react-router-dom';
import Routes from '../../../routes';
import SVG from 'react-inlinesvg';
import IconArrow from '../../../assets/icon/icon-arrow-right.svg';
import IconPhone from '../../../assets/icon/icon-phone.svg';
import IconAddress from '../../../assets/icon/icon-location.svg';
import IconDistr from '../../../assets/icon/icon-distribuidor.svg';
import IconEmail from '../../../assets/icon/icon-email.svg';
import PageInfoLayout from '../../../components/Layout/PageInfoLayout';
import AppStore from '../../../store/AppStore';
import { useTranslation } from 'react-i18next';

const Contacts = () => {
  const { t } = useTranslation();
  const contactsData = [
    {
      name: t('phone'),
      icon: IconPhone,
      value: t('contactPhoneValue'),
    },
    {
      name: t('address'),
      icon: IconAddress,
      value: t('contactAddressValue'),
    },
    {
      name: t('officialDistributor'),
      icon: IconDistr,
      value: t('contactDistributorValue'),
    },
    {
      name: t('email'),
      icon: IconEmail,
      value: t('contactEmailValue'),
    },
  ];
  return (
    <PageInfoLayout className="info-page-faq">
      <>
        <MediaQuery maxWidth={breakpoints['sm-max']}>
          <div className="pages-info-layout__button-back-mobile">
            <NavLink
              to={AppStore.loggedIn ? Routes.home : Routes.login}
              className="main-button icon"
            >
              <SVG className="rotate-arrow" src={IconArrow} width={13} />
              <span dangerouslySetInnerHTML={{ __html: t('backToMain') }} />
            </NavLink>
          </div>
        </MediaQuery>
        <h1>
          <b>{t('footer.contact')}</b>
        </h1>
        <MediaQuery minWidth={breakpoints['md-min']}>
          <div className="pages-info-layout__buttons">
            <NavLink
              to={AppStore.loggedIn ? Routes.home : Routes.login}
              className="main-button icon"
            >
              <SVG className="rotate-arrow" src={IconArrow} width={13} />
              <span dangerouslySetInnerHTML={{ __html: t('backToMain') }} />
            </NavLink>
          </div>
        </MediaQuery>
      </>
      <div className="info-page-faq__wrapper-content">
        <div className="info-page-faq__content">
          <h2 className="sub-title">{t('footer.feedback')}</h2>
          <div className="pages-info-layout__contacts">
            {contactsData.map((contact) => {
              return (
                <div className="pages-info-layout__contacts-item" key={contact.name}>
                  <div className="pages-info-layout__contacts-icon">
                    <SVG src={contact.icon} width={28} />
                  </div>
                  <div className="pages-info-layout__contacts-text">
                    <h3>{contact.name}</h3>
                    <div dangerouslySetInnerHTML={{ __html: contact.value }} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <MediaQuery maxWidth={breakpoints['sm-max']}>
          <div className="pages-info-layout__button-back-mobile">
            <NavLink
              to={AppStore.loggedIn ? Routes.home : Routes.login}
              className="main-button icon"
            >
              <SVG className="rotate-arrow" src={IconArrow} width={13} />
              <span dangerouslySetInnerHTML={{ __html: t('backToMain') }} />
            </NavLink>
          </div>
        </MediaQuery>
      </div>
    </PageInfoLayout>
  );
};
export default Contacts;
