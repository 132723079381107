import React, { useEffect, useState } from 'react';
import PageUserLayout from '../../components/Layout/PageUserLayout';
import { useTranslation } from 'react-i18next';
import ShopsList from '../../components/ShopsList';
import UserStore from '../../store/UserStore';
import { toast } from 'react-toastify';
import Spinner from '../../components/Base/Spinner';
import { Link } from 'react-router-dom';
import Routes from '../../routes';
import IconArrow from '../../assets/icon/icon-arrow-right.svg';
import SVG from 'react-inlinesvg';

const ProductsPage = () => {
  const { t } = useTranslation();
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    initPage();
  }, []);

  const initPage = async () => {
    try {
      setPageLoading(true);
      await UserStore.getNickname();
    } catch (e) {
      toast.error(t('somethingWentWrong'));
    } finally {
      setPageLoading(false);
    }
  };

  const latteUrl = Number(process.env.REACT_APP_IS_DEV)
    ? 'https://dev.latte-beauty.com/'
    : 'https://lattebeauty.com/';

  const shops = [
    {
      name: 'Lattebeauty',
      src: '/images/shop-latte.png',
      link: `${latteUrl}gift?model=${UserStore.nicknameInfo[0].keyword}`,
      id: '2',
    },
  ];
  return (
    <PageUserLayout className="products-page">
      <div className="content-page__wrapper">
        {!pageLoading ? (
          <>
            {UserStore.nicknameInfo[0].keyword ? (
              <>
                <h2 className="products-page__title">{t('bestWeekOffers')}</h2>
                <h3 className="products-page__sub-title">{t('productSubTitle')}</h3>
                {shops.map((shop) => {
                  return <ShopsList shop={shop} key={shop.name} />;
                })}
              </>
            ) : (
              <div className="products-page__empty">
                <div className="products-page__empty-label">{t('needToFillNickname')}</div>
                <Link to={Routes.userInfo} className="products-page__empty-btn main-button icon">
                  {t('fill')}
                  <SVG src={IconArrow} width={13} />
                </Link>
              </div>
            )}
          </>
        ) : (
          <div className="products-page__loader">
            <Spinner width={40} height={40} />
          </div>
        )}
      </div>
    </PageUserLayout>
  );
};

export default ProductsPage;
