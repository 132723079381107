import React from 'react';
import { observer } from 'mobx-react-lite';
import ChartBalance from '../../components/Balance/ChartBalance';
import PageUserLayout from '../../components/Layout/PageUserLayout';

const HomePage = () => {
  return (
    <PageUserLayout className="home-page">
      <ChartBalance />
    </PageUserLayout>
  );
};

export default observer(HomePage);
