import React, { useEffect, useRef, useState } from 'react';
import IconArrow from '../../assets/icon/icon-arrow-right.svg';
import SVG from 'react-inlinesvg';
import CookieSwitcher from './CookieOptionSwitcher';
import Routes from '../../routes';
import { useTranslation } from 'react-i18next';
import useRouter from '../../hooks/useRouter';

const CookieModal = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const [showCookieModal, setShowCookieModal] = useState(false);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [fullPagePaddingAdded, setFullPagePaddingAdded] = useState(false);
  const cookieOptionsDefaultStatus = {
    mandatory: true,
    statistical: true,
    socialMedia: true,
  };
  const [cookieOptionAcceptStatus, setCookieOptionAcceptStatus] = useState(
    cookieOptionsDefaultStatus,
  );
  const cookieOptions = [
    {
      name: t('mandatory'),
      text: t('optionMandatory'),
      fieldName: 'mandatory',
      required: true,
    },
    {
      name: t('statistical'),
      text: t('optionStatistical'),
      fieldName: 'statistical',
    },
    {
      name: t('socialMedia'),
      text: t('optionSocialMedia'),
      fieldName: 'socialMedia',
    },
  ];
  const cookieModalBlock = useRef(null);

  useEffect(() => {
    const cookieAcceptStatus = Boolean(localStorage.getItem('cookieAccepted'));
    setShowCookieModal(!cookieAcceptStatus);
  }, []);

  useEffect(() => {
    const fullPageContainer = document.querySelector('.full-page');
    if (showCookieModal) {
      const modalHeight = cookieModalBlock?.current?.clientHeight;
      fullPageContainer.style.paddingBottom = `${modalHeight}px`;
      setFullPagePaddingAdded(true);
    } else if (fullPagePaddingAdded) {
      fullPageContainer.style.paddingBottom = `0px`;
    }
  }, [showCookieModal]);

  const acceptCookieHandleClick = (useDefaultOptions) => {
    let savedOptions;
    if (useDefaultOptions) {
      setCookieOptionAcceptStatus(cookieOptionsDefaultStatus);
      savedOptions = cookieOptionsDefaultStatus;
    } else {
      savedOptions = cookieOptionAcceptStatus;
    }
    setShowCookieModal(false);
    setShowAdvancedOptions(false);
    localStorage.setItem('cookieAccepted', 'accepted');
    localStorage.setItem('cookieOptions', JSON.stringify(savedOptions));
  };

  const showAdvancedHandleCLick = () => {
    setShowCookieModal(false);
    setShowAdvancedOptions(true);
  };

  const closeAdvancedHandleCLick = () => {
    setShowCookieModal(true);
    setShowAdvancedOptions(false);
  };

  const openPolicyHandleCLick = () => {
    closeAdvancedHandleCLick();
    router.push(Routes.policy);
  };

  return (
    <>
      {showCookieModal ? (
        <div ref={cookieModalBlock} className="cookie-modal">
          <div className="cookie-modal__btn-container">
            <button
              onClick={() => {
                acceptCookieHandleClick(true);
              }}
              className="main-button button-accent cookie-modal__btn-btn"
            >
              {t('confirmAndContinue')}
            </button>
          </div>
          <div className="cookie-modal__text-container">
            <div className="cookie-modal__text">
              {t('cookieText')}{' '}
              <span onClick={showAdvancedHandleCLick}>
                {t('advanced')} <SVG src={IconArrow} width={10} />
              </span>
            </div>
          </div>
        </div>
      ) : null}
      {showAdvancedOptions ? (
        <div className="cookie-modal__advanced-container">
          <div onClick={closeAdvancedHandleCLick} className="cookie-modal__advanced-overlay" />
          <div className="cookie-modal__advanced">
            <div className="cookie-modal__advanced-title">{t('cookieText')}</div>
            <div className="cookie-modal__options">
              {cookieOptions.map((option) => {
                return (
                  <CookieSwitcher
                    cookieOptionAcceptStatus={cookieOptionAcceptStatus}
                    setCookieOptionAcceptStatus={setCookieOptionAcceptStatus}
                    optionData={option}
                    key={option.name}
                  />
                );
              })}
            </div>
            <div className="cookie-modal__advanced-buttons">
              <button
                onClick={() => {
                  acceptCookieHandleClick(false);
                }}
                className="cookie-modal__advanced-btn main-button button-accent"
              >
                {t('acceptTheSelected')}
              </button>
              <div onClick={openPolicyHandleCLick} className="cookie-modal__policy-link">
                {t('cookieMore')} <SVG src={IconArrow} width={10} />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CookieModal;
