import React, { useState } from 'react';
import StatisticsTable from './StatisticsTable';
import DatePickerMain from '../../components/Base/DatePickerMain';
import MediaQuery from 'react-responsive';
import breakpoints from '../../utils/breakpoints';
import SVG from 'react-inlinesvg';
import IconDatePicker from '../../assets/icon/icon-datePicker.svg';
import Modal from 'react-responsive-modal';
import PageUserLayout from '../../components/Layout/PageUserLayout';
import { useTranslation } from 'react-i18next';

const StatisticsPage = () => {
  const { t } = useTranslation();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [modalDatePicker, setModalDatePicker] = useState(false);
  const [date, setDate] = useState(null);

  const handleDateSelect = (dateStart, dateEnd, activeFilterDate) => {
    setDate({
      dateStart,
      dateEnd: dateEnd ? new Date(dateEnd) : null,
      filter: activeFilterDate,
    });
    setOpenDatePicker(!openDatePicker);
  };
  const openModalDatePicker = () => {
    setModalDatePicker(!modalDatePicker);
  };
  const closeModal = () => {
    setModalDatePicker(false);
  };
  return (
    <PageUserLayout className="statistics-page">
      <div className="content-page__wrapper">
        <MediaQuery minWidth={breakpoints['xs-max']}>
          <h2 className="statistics-page__title">
            {t('menu.statistics')}
            <span onClick={openModalDatePicker}>
              <SVG src={IconDatePicker} width={14} /> {t('selectPeriod')}
            </span>
          </h2>
        </MediaQuery>
        <MediaQuery maxWidth={breakpoints['xs-max']}>
          <div className="date-picker__mobile-open" onClick={openModalDatePicker}>
            <SVG src={IconDatePicker} width={14} /> {t('selectPeriod')}
          </div>
        </MediaQuery>
        <div>
          <Modal
            classNames={{ root: 'date-picker__modal-main' }}
            open={modalDatePicker}
            onClose={closeModal}
            center
          >
            <DatePickerMain handleDateSelect={handleDateSelect} />
          </Modal>
          <StatisticsTable date={date} />
        </div>
      </div>
    </PageUserLayout>
  );
};
export default StatisticsPage;
