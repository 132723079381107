import { apiInstance } from '../api';

export const getCountries = async () => {
  try {
    const getCountries = await apiInstance().get(`/countries`);
    return getCountries.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
