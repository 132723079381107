import React from 'react';
import PageUserLayout from '../../components/Layout/PageUserLayout';
import CashOutTable from '../../components/Withdraw/WithdrawTable';
import { useTranslation } from 'react-i18next';

const WithdrawPage = () => {
  const { t } = useTranslation();
  return (
    <PageUserLayout className="withdraw-page">
      <div className="content-page__wrapper">
        <div className="withdraw-page__title-container">
          <h2>{t('withdrawalRequests')}</h2>
        </div>
        <CashOutTable />
      </div>
    </PageUserLayout>
  );
};

export default WithdrawPage;
