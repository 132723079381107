import React from 'react';
import PageUserLayout from '../../components/Layout/PageUserLayout';
import { useTranslation } from 'react-i18next';
import InfoUserFormFull from './InfoUserFormFull';

const InfoUserPage = () => {
  const { t } = useTranslation();

  return (
    <PageUserLayout className="info-user-page">
      <div className="content-page__wrapper">
        <h2>{t('menu.infoUser')}</h2>
        <InfoUserFormFull />
      </div>
    </PageUserLayout>
  );
};
export default InfoUserPage;
