import { apiInstance } from '../api';

export const loginUser = async (email, password) => {
  try {
    const loginUserRes = await apiInstance().post('/login', { email, password });
    return loginUserRes.data;
  } catch (e) {
    throw e.response.data;
  }
};

export const authSocialAccounts = async (token, name) => {
  try {
    const accounts = await apiInstance().post(`/login/social`, {
      token,
      name,
    });
    return accounts.data;
  } catch (e) {
    throw e.response.data;
  }
};

export const registrationUser = async (dataUser) => {
  try {
    const registrationUserRes = await apiInstance().post('/users', { ...dataUser });
    return registrationUserRes.data;
  } catch (e) {
    throw e.response.data;
  }
};
