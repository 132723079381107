import Routes from '../routes';
import { useTranslation } from 'react-i18next';
import IconHome from '../assets/icon/icon-home-menu.svg';
import IconInfoUser from '../assets/icon/icon-info-user-menu.svg';
import IconProfile from '../assets/icon/icon-profile-menu.svg';
import IconBalance from '../assets/icon/icon-balance-menu.svg';
import IconProducts from '../assets/icon/icon-package.svg';
import IconMoney from '../assets/icon/icon-money.svg';
// import IconStatistics from '../assets/icon/icon-statistics-menu.svg';

export function MenuPageList() {
  const { t } = useTranslation();
  return [
    { route: Routes.home, label: t('menu.home'), icon: IconHome },
    { route: Routes.products, label: t('menu.products'), icon: IconProducts },
    { route: Routes.userInfo, label: t('menu.infoUser'), icon: IconInfoUser },
    { route: Routes.profile, label: t('menu.profile'), icon: IconProfile },
    // пока скроем
    // { route: Routes.statistics, label: t('menu.statistics'), icon: IconStatistics },
    { route: Routes.balance, label: t('menu.myBalance'), icon: IconBalance },
    { route: Routes.withdraw, label: t('withdrawalOfFounds'), icon: IconMoney },
  ];
}
