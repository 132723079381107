import { action, computed, makeObservable, observable } from 'mobx';
import { getCountries } from '../api/actions/countries';
import { getCurrency } from '../api/actions/currency';

class AppStore {
  appIsReady = false;
  userIsAuthToken = false;
  userIsAuthId = false;
  isRegisterPage = false;
  userId = null;
  countriesList = [];
  currenciesList = [];

  constructor() {
    makeObservable(this, {
      appIsReady: observable,
      userIsAuthToken: observable,
      userIsAuthId: observable,
      initApp: action,
      isRegisterPage: observable,
      statusRegisterPage: action,
      loggedIn: computed,
      countriesList: observable,
      currenciesList: observable,
    });
  }

  initApp = () => {
    this.appIsReady = true;
    this.userIsAuthToken = Boolean(localStorage.getItem('token'));
    this.userIsAuthId = Boolean(localStorage.getItem('userId'));
  };

  statusRegisterPage = (status) => {
    this.isRegisterPage = status;
  };

  get loggedIn() {
    return this.userIsAuthToken && this.userIsAuthId;
  }

  setCountries = async () => {
    try {
      const countries = await getCountries();
      if (countries.length) {
        this.countriesList = countries;
      }
      return countries;
    } catch (e) {}
  };
  setCurrencies = async () => {
    try {
      const currency = await getCurrency();
      if (currency.length) {
        this.currenciesList = currency;
      }
      return currency;
    } catch (e) {}
  };
}

export default new AppStore();
