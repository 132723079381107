import React from 'react';
import SVG from 'react-inlinesvg';
import IconArrow from '../../assets/icon/icon-arrow-right.svg';
import IconNotification from '../../assets/icon/icon-notification.svg';
import MediaQuery from 'react-responsive';
import breakpoints from '../../utils/breakpoints';
import { useTranslation } from 'react-i18next';
import Routes from '../../routes';
import useRouter from '../../hooks/useRouter';

const Notifications = () => {
  const { t } = useTranslation();
  const router = useRouter();

  const handleCLick = () => {
    router.push(Routes.userInfo);
  };

  return (
    <div onClick={handleCLick} className="notifications">
      <div className="notifications__wrapper">
        <div className="notifications__item">
          <SVG className="notifications__icon-notification" src={IconNotification} width={20} />
          {t('cannotEarn')}
        </div>
        <div className="notifications__item">
          <MediaQuery minWidth={breakpoints['xs-max']}>{t('fill')}</MediaQuery>
          <SVG className="notifications__icon-arrow" src={IconArrow} width={6} />
        </div>
      </div>
    </div>
  );
};
export default Notifications;
