import React, { useEffect, useState } from 'react';
import DefaultInput from '../../components/Base/DefaultInput';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import IconChecked from '../../assets/icon/icon-checked.svg';
import Spinner from '../../components/Base/Spinner';
import colorsVars from '../../utils/colorsVars';
import UserStore from '../../store/UserStore';
import AppStore from '../../store/AppStore';
import { observer } from 'mobx-react-lite';
import Select from 'react-select';
import { toast } from 'react-toastify';

const InfoUserFormFull = () => {
  const { t } = useTranslation();
  const { user, userInit, nicknameInfo, usersContacts } = UserStore;
  const { countriesList } = AppStore;
  const [countries, setCountries] = useState(countriesList);
  const initialCountry = countries.find((country) => country.isoCode === usersContacts.countryCode);
  const [formLoading, setFormLoading] = useState(false);
  const [buttonLocked, setButtonLocked] = useState(true);
  const initialFormValues = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    nickname: nicknameInfo[0]?.keyword || '',
    city: usersContacts.city,
    address: usersContacts.address,
    phone: usersContacts.phone,
    selectCountry: initialCountry,
  };
  const [formValues, setFormValues] = useState(initialFormValues);
  const noErrorsInputs = {
    firstName: '',
    lastName: '',
    email: '',
    nickname: '',
    city: '',
    address: '',
    phone: '',
    selectCountry: '',
  };
  const [formErrors, setFormErrors] = useState(noErrorsInputs);

  const handleChange = (fieldName, withoutSpaces, e) => {
    setButtonLocked(false);
    let value = e.target.value;
    if (fieldName === 'phone') {
      value = value.replace(/\D/g, '');
    }
    setFormErrors((prevState) => {
      return { ...prevState, [fieldName]: '' };
    });
    setFormValues((prevState) => {
      return { ...prevState, [fieldName]: withoutSpaces ? value.trim() : value };
    });
  };

  const handleChangeSelect = (selectedOption) => {
    setButtonLocked(false);
    setFormValues((prevState) => {
      return { ...prevState, selectCountry: selectedOption };
    });
    setFormErrors((prevState) => {
      return { ...prevState, selectCountry: '' };
    });
  };

  const validate = (values) => {
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const regexNick = /^[a-zA-Z0-9-_]+$/;
    const errorsValues = {};

    if (!values.firstName.trim()) {
      errorsValues.firstName = t('authorization.errorsEmptyInput');
    }
    if (!values.lastName.trim()) {
      errorsValues.lastName = t('authorization.errorsEmptyInput');
    }
    if (!values.email.trim()) {
      errorsValues.email = t('authorization.errorsEmptyInput');
    } else if (!regexEmail.test(values.email)) {
      errorsValues.email = t('authorization.noValidEmail');
    }
    if (!values.nickname) {
      errorsValues.nickname = t('authorization.errorsEmptyInput');
    } else if (!regexNick.test(values.nickname)) {
      errorsValues.nickname = t('nicknameInvalid');
    }
    console.log(!values.selectCountry);
    if (!values.selectCountry) {
      errorsValues.selectCountry = t('selectCountry');
    }
    if (!values.city.trim()) {
      errorsValues.city = t('authorization.errorsEmptyInput');
    }
    if (!values.address.trim()) {
      errorsValues.address = t('authorization.errorsEmptyInput');
    }
    if (!values.phone.trim()) {
      errorsValues.phone = t('authorization.errorsEmptyInput');
    } else if (values.phone.length < 6) {
      errorsValues.phone = t('invalidPhone');
    }

    const hasValidateErrors = Boolean(
      Object.keys(errorsValues).find((key) => errorsValues[key] !== ''),
    );

    setFormErrors((prevState) => {
      return { ...prevState, ...errorsValues };
    });

    return hasValidateErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormLoading(true);
    const hasValidateErrors = validate(formValues);

    if (hasValidateErrors) {
      setFormLoading(false);
      return;
    }

    const userData = {
      firstName: formValues.firstName.trim(),
      lastName: formValues.lastName.trim(),
      email: formValues.email.trim(),
    };
    const nicknameData = {
      keyword: formValues.nickname,
    };
    const contactsData = {
      countryIsoCode: formValues.selectCountry.isoCode,
      city: formValues.city.trim(),
      address: formValues.address.trim(),
      phone: formValues.phone,
      isDefault: true,
    };

    try {
      await UserStore.editUserData(userData);
      if (nicknameInfo[0].id) {
        await UserStore.editNickname(nicknameData);
      } else {
        await UserStore.addNickname(nicknameData);
      }
      if (usersContacts.id) {
        await UserStore.editAddressUser(contactsData);
      } else {
        await UserStore.addAddressUser(contactsData);
      }
      await UserStore.userCheckBlankInfo();
      toast.success(t('dataChangedSuccess'));
    } catch (error) {
      if (error?.message === 'User duplicate.') {
        toast.error(t('regError'));
        setFormErrors((prevState) => {
          return { ...prevState, email: t('regError') };
        });
      } else if (error?.message === 'Keyword duplicate.') {
        toast.error(t('alreadyInUse'));
        setFormErrors((prevState) => {
          return { ...prevState, nickname: t('alreadyInUse') };
        });
      } else {
        toast.error(t('somethingWentWrong'));
      }
    } finally {
      setButtonLocked(true);
      setFormLoading(false);
    }
  };

  const initUserInfo = async () => {
    if (userInit) {
      if (countriesList.length === 0) {
        const countries = await AppStore.setCountries();
        setCountries(countries);
        const initialCountry = countries.find(
          (country) => country.isoCode === usersContacts.countryCode,
        );
        setFormValues({ ...initialFormValues, selectCountry: initialCountry });
      } else {
        setFormValues(initialFormValues);
      }
    }
  };

  useEffect(() => {
    initUserInfo();
  }, [userInit]);

  return (
    <div className="info-full-form__container">
      <form onSubmit={handleSubmit} className="info-full-form" action="">
        <div className="info-full-form__wrapper">
          <DefaultInput
            onChange={handleChange}
            name="lastName"
            value={formValues.lastName}
            label={t('lastName')}
            required
            error={formErrors.lastName}
          />
          <DefaultInput
            onChange={handleChange}
            name="firstName"
            value={formValues.firstName}
            label={t('name')}
            required
            error={formErrors.firstName}
          />
        </div>
        <div className="info-full-form__wrapper">
          <DefaultInput
            onChange={handleChange}
            name="email"
            value={formValues.email}
            label={t('authorization.email')}
            required
            withoutSpaces
            error={formErrors.email}
          />
          <DefaultInput
            onChange={handleChange}
            name="nickname"
            value={formValues.nickname}
            label={t('nickname')}
            required
            withoutSpaces
            error={formErrors.nickname}
          />
        </div>
        <div className="info-full-form__wrapper">
          <div className="info-full-form__field">
            <label className="label">{t('country')}*</label>
            <Select
              placeholder={t('select')}
              value={formValues.selectCountry}
              onChange={handleChangeSelect}
              options={countries}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ isoCode }) => isoCode}
              className={`country-select
                 ${formErrors.selectCountry ? 'no-valid-input' : ''}`}
            />
            {formErrors.selectCountry && (
              <div className="input_error">{formErrors.selectCountry}</div>
            )}
          </div>
          <DefaultInput
            onChange={handleChange}
            name="city"
            value={formValues.city}
            label={t('city')}
            required
            error={formErrors.city}
          />
        </div>
        <div className="info-full-form__wrapper">
          <DefaultInput
            onChange={handleChange}
            name="address"
            value={formValues.address}
            label={t('address')}
            required
            error={formErrors.address}
          />
          <DefaultInput
            onChange={handleChange}
            name="phone"
            value={formValues.phone}
            label={t('phone')}
            required
            type="phone"
            error={formErrors.phone}
          />
        </div>
        <button
          disabled={buttonLocked || formLoading}
          className={`info-full-form__button main-button button-accent ${
            formLoading ? 'main-button_loading' : ''
          }`}
        >
          <span>
            <SVG src={IconChecked} width={12} /> {t('saveChanges')}
          </span>
          {formLoading && (
            <div className="main-button__btn-loader">
              <Spinner width={18} height={18} borderWidth={2} color={colorsVars.colorLight} />
            </div>
          )}
        </button>
      </form>
    </div>
  );
};

export default observer(InfoUserFormFull);
