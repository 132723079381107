import Routes from '../routes';
import { useTranslation } from 'react-i18next';

export function FooterList() {
  const { t } = useTranslation();
  return {
    firstMenu: [
      { route: Routes.howIsWork, label: t('footer.howWorks') },
      { route: Routes.faq, label: t('footer.faq') },
      { route: Routes.about, label: t('footer.about') },
      { route: Routes.contact, label: t('footer.contact') },
      { route: Routes.feedBackForm, label: t('footer.feedback') },
    ],
    secondMenu: [
      { route: Routes.dmca, label: t('footer.dmca') },
      { route: Routes.policy, label: t('footer.policy') },
      { route: Routes.terms, label: t('footer.terms') },
    ],
  };
}
