import { apiInstance } from '../api';

export const createWithdraw = async (amount) => {
  try {
    const res = await apiInstance().post('/withdrawals', {
      amount,
    });
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const getWithdrawalsList = async (page, limit = 15) => {
  try {
    const res = await apiInstance().get(`/withdrawals`, {
      params: {
        page,
        limit,
      },
    });
    return res.data;
  } catch (e) {
    throw e;
  }
};
