import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import UserStore from '../../store/UserStore';
import { useTranslation } from 'react-i18next';

const ChartBalance = () => {
  const { t } = useTranslation();
  const [highchartsData, setHighchartsData] = useState([]);
  const [currency, setCurrency] = useState('USD');

  const options = {
    time: {
      useUTC: false,
    },
    title: {
      text: t('balance'),
      align: 'center',
      x: 5,
    },
    rangeSelector: {
      enabled: false,
    },
    xAxis: {
      type: 'datetime',
      time: {
        timezone: 'Europe/London',
      },
      ordinal: false,
      labels: {
        rotation: -45,
        format: '{value:%Y-%m-%d}',
        style: {
          fontSize: '13px',
          fontFamily: 'Arial, sans-serif',
        },
      },
    },
    yAxis: {
      min: 0,
      opposite: false,
      title: {
        text: `${t('currency')} ${currency}`,
      },
    },
    // subtitle: {
    //   text: 'Summarized according to chosen interval',
    //   align: 'center',
    //   x: 5,
    // },
    legend: {
      enabled: false,
    },
    credits: { enabled: false },
    navigator: {
      enabled: true,
    },
    plotOptions: {
      line: {
        animation: false,
        lineWidth: 1,
        turboThreshold: 0,
        marker: {
          enabled: true,
          radius: 1,
        },
        shadow: false,
      },
    },
    series: [
      {
        name: t('yourBalance'),
        data: highchartsData,
        dataGrouping: {
          approximation: 'MAX',
        },
        marker: {
          radius: 3,
          lineWidth: 1,
          lineColor: '#FFFFFF',
          width: 100,
          height: 10,
        },
        tooltip: {
          valueDecimals: 2,
        },
      },
    ],
    tooltip: {
      dateTimeLabelFormats: {
        day: '%Y-%m-%d',
      },
    },
  };
  useEffect(() => {
    // ФИКС непральная ширина  Highcharts при первой загрузке
    setTimeout(() => {
      console.log(highchartsData);
      if (Highcharts.charts[0]) {
        Highcharts.charts.forEach((chart) => {
          chart.reflow();
        });
      }
    }, 100);
  }, [highchartsData]);

  useEffect(() => {
    UserStore.getStatisticUser({
      partSize: 40,
    }).then((balanceData) => {
      balanceData?.length && setCurrency(balanceData[0].newUserBalance.currency.isoCode);
      setHighchartsData([
        ...balanceData.map((el) => [
          new Date(el.createdAt.replace(' ', 'T')).getTime(),
          Number(el.newUserBalance.amount),
        ]),
      ]);
    });
  }, []);
  return (
    <div className="chart-balance">
      <h2>{t('changingYourBalance')}</h2>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
export default ChartBalance;
