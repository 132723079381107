import React from 'react';
import Logo from '../Base/Logo';
import CountrySwitcher from '../CountrySwitcher';
import { NavLink } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import breakpoints from '../../utils/breakpoints';
import FooterMain from '../FooterMain';
import { FooterList } from '../../utils/FooterList';
import SVG from 'react-inlinesvg';
import IconQuestion from '../../assets/icon/icon-question.svg';

const PageInfoLayout = ({ children, className }) => {
  const footerList = FooterList();
  return (
    <div className={`pages-info-layout ${className || ''}`}>
      <div className="pages-info-layout__wrapper-title">
        <div className="pages-info-layout__menu">
          <Logo />
          <CountrySwitcher />
        </div>
        <div className="pages-info-layout__title">{children[0]}</div>
        <MediaQuery minWidth={breakpoints['md-min']}>
          <div className="pages-info-layout__footer">
            <div className="pages-info-layout__footer-menu">
              {footerList.firstMenu.map((menu, i) => {
                return (
                  <NavLink className="pages-info-layout__footer-link" exact to={menu.route} key={i}>
                    {menu.label}
                  </NavLink>
                );
              })}
            </div>
            <div className="pages-info-layout__footer-menu">
              <div className="pages-info-layout__footer-year">© 2022.PayFan</div>
              {footerList.secondMenu.map((menu, i) => {
                return (
                  <NavLink className="pages-info-layout__footer-link" exact to={menu.route} key={i}>
                    {menu.label}
                  </NavLink>
                );
              })}
              <div className="pages-info-layout__footer-link _question">
                <SVG src={IconQuestion} width={20} />
              </div>
            </div>
          </div>
        </MediaQuery>
      </div>
      <div className="pages-info-layout__wrapper-content">
        <div className="pages-info-layout__content">{children[1]}</div>
      </div>
      <MediaQuery minWidth={breakpoints['lg-max']}>
        <div className="pages-info-layout__wrapper-girl">
          <div className="pages-info-layout__img-girl">
            <img src="/images/girl-static.png" alt="" />
          </div>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={breakpoints['sm-max']}>
        <FooterMain className="footer-mobile" />
      </MediaQuery>
    </div>
  );
};
export default PageInfoLayout;
