import { apiInstance } from '../api';

export const getUser = async (userId) => {
  try {
    const getUser = await apiInstance().get(`/users/${userId}`);
    return getUser.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const editUser = async (userId, userData) => {
  try {
    const editUser = await apiInstance().patch(`/users/${userId}`, { ...userData });
    return editUser.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const uploadAvatarUser = async (userId, userAva) => {
  try {
    const editUser = await apiInstance().post(`/users/${userId}/avatar`, userAva);
    return editUser.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const addAddressUser = async (userId, userDataContacts) => {
  try {
    const contacts = await apiInstance().post(`/users/${userId}/addresses`, {
      ...userDataContacts,
    });
    return contacts.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const editAddressUser = async (userId, addressId, userDataContacts) => {
  try {
    const contacts = await apiInstance().patch(`/users/${userId}/addresses/${addressId}`, {
      ...userDataContacts,
    });
    return contacts.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const getNicknameUser = async (userId) => {
  try {
    const nickname = await apiInstance().get(`/users/${userId}/keywords`);
    return nickname.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const addNicknameUser = async (userId, userDataNickname) => {
  try {
    const nickname = await apiInstance().post(`/users/${userId}/keywords`, {
      ...userDataNickname,
    });
    return nickname.data;
  } catch (e) {
    throw e.response.data;
  }
};

export const editNicknameUser = async (userId, keywordId, userDataNickname) => {
  try {
    const nickname = await apiInstance().patch(`/users/${userId}/keywords/${keywordId}`, {
      ...userDataNickname,
    });
    return nickname.data;
  } catch (e) {
    throw e.response.data;
  }
};

export const checkingBlankUserInfoData = async (userId) => {
  try {
    const checkingBlank = await apiInstance().get(`/users/${userId}/check`);
    return checkingBlank.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
export const userStatistic = async (userId, userDataStatistic) => {
  try {
    const statistic = await apiInstance().get(`/users/${userId}/transactions`, {
      params: {
        id: userId,
        ...userDataStatistic,
      },
    });
    return statistic.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
