import React, { useEffect, useState } from 'react';
import MediaQuery from 'react-responsive';
import { observer } from 'mobx-react-lite';
import breakpoints from '../../utils/breakpoints';
import HeaderTop from '../HeaderTop';
import Notifications from '../Notifications';
import UserStore from '../../store/UserStore';

const PageUserLayout = ({ children, className }) => {
  const [showNotification, setShowNotification] = useState(false);
  useEffect(() => {
    setShowNotification(UserStore.isUserNoBlankInfo);
  }, [UserStore.isUserNoBlankInfo]);

  return (
    <div className={`content-page ${className || ''}`}>
      <MediaQuery minWidth={breakpoints['xs-max']}>
        <HeaderTop />
      </MediaQuery>
      {showNotification && <Notifications />}
      {children}
    </div>
  );
};
export default observer(PageUserLayout);
