import React, { useState } from 'react';
import BalanceTable from './BalanceTable';
import { observer } from 'mobx-react-lite';
import DatePickerMain from '../../components/Base/DatePickerMain';
import MediaQuery from 'react-responsive';
import breakpoints from '../../utils/breakpoints';
import SVG from 'react-inlinesvg';
import IconDatePicker from '../../assets/icon/icon-datePicker.svg';
import Modal from 'react-responsive-modal';
import PageUserLayout from '../../components/Layout/PageUserLayout';
import { useTranslation } from 'react-i18next';

const BalancePage = () => {
  const { t } = useTranslation();
  // const [openDatePicker, setOpenDatePicker] = useState(false);
  const [modalDatePicker, setModalDatePicker] = useState(false);
  const [date, setDate] = useState(null);

  const handleDateSelect = (dateStart, dateEnd, activeFilterDate) => {
    setDate({
      dateStart: dateStart ? new Date(dateStart) : null,
      dateEnd,
      filter: activeFilterDate,
    });

    setModalDatePicker(false);
  };
  const openModalDatePicker = () => {
    setModalDatePicker(!modalDatePicker);
  };
  const closeModal = () => {
    setModalDatePicker(false);
  };

  return (
    <PageUserLayout className="balance-page">
      <div className="content-page__wrapper">
        <MediaQuery minWidth={breakpoints['xs-max']}>
          <h2 className="balance-page__title">
            {t('menu.myBalance')}
            <span onClick={openModalDatePicker}>
              <SVG src={IconDatePicker} width={14} /> {t('selectPeriod')}
            </span>
          </h2>
        </MediaQuery>
        <MediaQuery maxWidth={breakpoints['xs-max']}>
          <div className="date-picker__mobile-open" onClick={openModalDatePicker}>
            <SVG src={IconDatePicker} width={14} /> {t('selectPeriod')}
          </div>
        </MediaQuery>
        <div>
          <Modal
            classNames={{ root: 'date-picker__modal-main' }}
            open={modalDatePicker}
            onClose={closeModal}
            center
          >
            <DatePickerMain handleDateSelect={handleDateSelect} />
          </Modal>
          <BalanceTable date={date} />
        </div>
      </div>
    </PageUserLayout>
  );
};
export default observer(BalancePage);
