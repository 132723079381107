import React from 'react';
import { observer } from 'mobx-react-lite';
import UserStore from '../../store/UserStore';
import ProfileForm from './ProfileForm';
import SVG from 'react-inlinesvg';
import IconClose from '../../assets/icon/icon-close.svg';
import { toast } from 'react-toastify';
import PageUserLayout from '../../components/Layout/PageUserLayout';
import ProfileFormPass from './ProfileFormPass';
import { useTranslation } from 'react-i18next';

const ProfilePage = () => {
  const { t } = useTranslation();
  const avatarSrc = UserStore.user.avatar ? UserStore.user.avatar : '/images/avatar.png';
  const handleUploadAvaChange = (file) => {
    const url = file[0];
    const fileFormData = new FormData();
    fileFormData.append('avatar', url);
    UserStore.uploadAvatarUser(fileFormData)
      .then(() => {
        toast.success(t('avatarUploaded'));
      })
      .catch((error) => {
        toast.error(` ${error}`);
      });
  };

  return (
    <PageUserLayout className="profile-page">
      <div className="content-page__wrapper">
        <h2>{t('userProfile')}</h2>
        <div className="profile-page__avatar-wrapper">
          <label className="profile-page__avatar" htmlFor="input-upload" title="сменить аватар">
            <div className="profile-page__avatar-img">
              <img src={avatarSrc} alt="" />
            </div>
          </label>
          <div className="profile-page__avatar-item-wrapper">
            <div className="profile-page__avatar-item">
              <div className="profile-page__avatar-text">{t('profileImage')}</div>
              <div className="profile-page__avatar-delete isHidden">
                <SVG src={IconClose} width={12} /> {t('delete')}
              </div>
            </div>
            <label className="profile-page__avatar-text-upload" htmlFor="input-upload">
              {t('upload')}
            </label>
          </div>
        </div>
        <input
          id="input-upload"
          type="file"
          onChange={(e) => {
            handleUploadAvaChange(e.target.files);
          }}
        />
        <ProfileForm />
        <ProfileFormPass />
      </div>
    </PageUserLayout>
  );
};
export default observer(ProfilePage);
