import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import Select from 'react-select';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import UserStore from '../../store/UserStore';
import { toast } from 'react-toastify';

import TimeZones from '../../utils/TimeZones';
import IconChecked from '../../assets/icon/icon-checked.svg';
import AppStore from '../../store/AppStore';
import Spinner from '../../components/Base/Spinner';
import colorsVars from '../../utils/colorsVars';

const ProfileForm = () => {
  const { t } = useTranslation();

  const errorsValues = {
    email: '',
    selectTime: '',
    currency: '',
  };
  const [currenciesList, setCurrenciesList] = useState([]);
  const initialTimezone = TimeZones.find((zone) => zone.tzCode === UserStore.user.timezone);
  const initialCurrency = currenciesList.find(
    (currency) => currency.isoCode === UserStore.user.currency,
  );
  const [formValues, setFormValues] = useState(() => ({
    email: UserStore.user.email,
    selectTime: '',
    currency: '',
  }));
  const [showLoader, setShowLoader] = useState(false);
  const [formErrors, setFormErrors] = useState(() => errorsValues);
  const [submitButtonLocked, setSubmitButtonLocked] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();

    setShowLoader(true);
    setSubmitButtonLocked(true);
    const profileData = {
      email: formValues.email,
      timezone: formValues.selectTime.tzCode,
      currency: formValues.currency.isoCode,
    };

    UserStore.editUserData(profileData)
      .then(() => {
        setShowLoader(false);
        setSubmitButtonLocked(true);
        toast.success(t('dataChangedSuccess'));
        setFormValues((prevState) => {
          const localValues = { ...prevState, confirmPassword: '' };
          return { ...localValues };
        });
      })
      .catch((error) => {
        setShowLoader(false);
        setSubmitButtonLocked(false);
        toast.error(`Ошибка : ${error}`);
      });
  };

  const handleChangeInput = (type, event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => {
      const localValues = { ...prevState, [name]: value };
      validate(localValues);
      return { ...localValues };
    });
  };

  const validate = (values) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (values.email === '') {
      errorsValues.email = `${t('authorization.errorsEmptyInput')}`;
    } else if (!regex.test(values.email)) {
      errorsValues.email = `${t('authorization.noValidEmail')}`;
    }
    /* if (values.selectTime === '') {
      errorsValues.selectTime = 'Выберете часовой пояс';
    } */
    const foundErrorsValues = Object.keys(errorsValues).find((key) => errorsValues[key] !== '');

    if (foundErrorsValues) {
      setSubmitButtonLocked(true);
    } else {
      setSubmitButtonLocked(false);
    }
    setFormErrors(errorsValues);
  };

  const classErrorForm = (error) => {
    return error !== '' && error !== null ? 'no-valid-input' : '';
  };

  /* const handleChangeSelect = (selectedOption) => {
    setFormValues((prevState) => {
      const localValues = { ...prevState, selectTime: selectedOption };
      validate(localValues);
      return { ...localValues };
    });
  }; */
  const handleChangeSelectCurrency = (selectedOption) => {
    setFormValues((prevState) => {
      const localValues = { ...prevState, currency: selectedOption };
      validate(localValues);
      return { ...localValues };
    });
  };
  useEffect(() => {
    if (AppStore.currenciesList.length === 0) {
      AppStore.setCurrencies().then((currencies) => {
        setCurrenciesList(currencies);
      });
    } else {
      setCurrenciesList(AppStore.currenciesList);
    }
  }, []);

  useEffect(() => {
    if (UserStore.user.currency && AppStore.currenciesList.length) {
      setFormValues({
        email: UserStore.user.email,
        selectTime: initialTimezone || '',
        currency: initialCurrency,
      });
    }
  }, [UserStore.userInit, currenciesList.length]);

  return (
    <form className="profile-page__form" onSubmit={handleSubmit}>
      <div className="profile-page__form-field-wrapper">
        <div className="profile-page__form-field">
          <label className="label">{t('authorization.email')}*</label>
          <input
            type="text"
            placeholder={t('authorization.email')}
            name="email"
            value={formValues.email}
            autoComplete="off"
            onChange={(event) => handleChangeInput('email', event)}
            className={`input profile-page__form-input
                 ${classErrorForm(formErrors.email)}`}
          />
          {formErrors.email && <div className="input_error">{formErrors.email}</div>}
        </div>

        <div className="profile-page__form-field">
          <label className="label">{t('currency')}</label>
          <Select
            isDisabled={UserStore.user.balance > 0}
            value={formValues.currency}
            onChange={handleChangeSelectCurrency}
            getOptionLabel={({ isoCode }) => isoCode}
            getOptionValue={({ isoCode }) => isoCode}
            options={currenciesList}
            className={`currency-switcher
                 ${classErrorForm(formErrors.currency)}`}
          />
          {formErrors.selectTime && <div className="input_error">{formErrors.currency}</div>}
        </div>
      </div>
      {/* <div className="profile-page__form-field-wrapper">
         <div className="profile-page__form-field">
          <label className="label">{t('timeZone')}</label>
          <Select
            placeholder={t('select')}
            value={formValues.selectTime}
            onChange={handleChangeSelect}
            getOptionLabel={({ label }) => label}
            getOptionValue={({ tzCode }) => tzCode}
            options={TimeZones}
            className={`time-switcher
                 ${classErrorForm(formErrors.selectTime)}`}
          />
          {formErrors.selectTime && <div className="input_error">{formErrors.selectTime}</div>}
        </div>
      </div> */}
      <button
        type="submit"
        disabled={submitButtonLocked}
        className={`main-button  button-accent icon ${showLoader ? 'main-button_loading' : ''}`}
      >
        <span>
          <SVG src={IconChecked} width={12} />
          {t('saveChanges')}
        </span>
        {showLoader && (
          <div className="main-button__btn-loader">
            <Spinner width={18} height={18} borderWidth={2} color={colorsVars.colorLight} />
          </div>
        )}
      </button>
    </form>
  );
};
export default observer(ProfileForm);
