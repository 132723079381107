import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { ru, en, es } from './translations';

const fallbackLng = ['en'];
const availableLanguages = ['en', 'ru', 'es'];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en,
      ru,
      es,
    },
    debug: false,
    fallbackLng,
    detection: { order: ['localStorage', 'navigator'], caches: ['localStorage'] },
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
      useSuspense: false,
    },
  });

export default i18n;
