import { apiInstance } from '../api';

export const getProducts = async (page, limit, language) => {
  try {
    const getUser = await apiInstance().get('/products', {
      params: {
        page,
        limit,
        language,
      },
    });
    return getUser.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
