import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import AppStore from '../store/AppStore';
import Routes from '../routes';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const loggedInWithUserId = AppStore.loggedIn;

  return (
    <Route
      {...rest}
      render={(props) =>
        loggedInWithUserId ? <Component {...props} /> : <Redirect to={Routes.login} />
      }
    />
  );
};

export default observer(PrivateRoute);
