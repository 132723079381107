import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import AppStore from './store/AppStore';
import UserStore from './store/UserStore';
import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';
import SignUpPage from './pages/SignUpPage';
import Spinner from './components/Base/Spinner';
import Routes from './routes';
import PrivateRoute from './pages/PrivateRoute';
import ProfilePage from './pages/ProfilePage';
import InfoUserPage from './pages/InfoUserPage';
import StatisticsPage from './pages/StatisticsPage';
import BalancePage from './pages/BalancePage';
import HowItWorks from './pages/PagesInfo/HowItWorks';
import FeedBackFormPage from './pages/PagesInfo/FeedBackForm';
import FAQ from './pages/PagesInfo/FAQ';
import Policy from './pages/PagesInfo/Policy';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './components/Layout';
import ProductsPage from './pages/ProductsPage';
import WithdrawPage from './pages/WithdrawPage';
import CookieModal from './components/CookieModal';
import AboutUs from './pages/PagesInfo/AboutUs';
import Contacts from './pages/PagesInfo/Contacts';

const App = () => {
  useEffect(() => {
    AppStore.initApp();
    if (AppStore.loggedIn) {
      UserStore.getUser().then(() => {
        UserStore.userCheckBlankInfo();
        UserStore.setAuthSocialName();
      });
    }
  }, []);

  return (
    <>
      <Router>
        <div className="full-page">
          {AppStore.appIsReady ? (
            <Layout>
              <Switch>
                <Route path={Routes.login} exact component={SignUpPage} />
                <Route path={Routes.howIsWork} exact component={HowItWorks} />
                <Route path={Routes.policy} exact component={Policy} />
                <Route path={Routes.feedBackForm} exact component={FeedBackFormPage} />
                <Route path={Routes.faq} exact component={FAQ} />
                <Route path={Routes.about} exact component={AboutUs} />
                <Route path={Routes.contact} exact component={Contacts} />
                <PrivateRoute path={Routes.home} exact component={HomePage} />
                <PrivateRoute path={Routes.products} exact component={ProductsPage} />
                <PrivateRoute path={Routes.profile} exact component={ProfilePage} />
                <PrivateRoute path={Routes.userInfo} exact component={InfoUserPage} />
                <PrivateRoute path={Routes.statistics} exact component={StatisticsPage} />
                <PrivateRoute path={Routes.balance} exact component={BalancePage} />
                <PrivateRoute path={Routes.withdraw} exact component={WithdrawPage} />
                <PrivateRoute path="*" component={NotFoundPage} />
              </Switch>
            </Layout>
          ) : (
            <Spinner width={40} height={40} center />
          )}
        </div>
        <CookieModal />
      </Router>

      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
      />
    </>
  );
};

export default observer(App);
