import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <div className="not-found-page content-page">
      <div className="not-found-page__container">
        <div className="not-found-page__title">404</div>
        <div className="not-found-page__text">
          {t('others.notFound')}. <Link to="/">{t('backToMain')}</Link>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
