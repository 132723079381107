import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import Spinner from '../Base/Spinner';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { getWithdrawalsList } from '../../api/actions/withdrawals';
import WithdrawTableItem from './WithdrawTableItem';
import UserStore from '../../store/UserStore';

const WithdrawTable = () => {
  const [hasNextPage, setHasNextPage] = useState(true);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [withdrawList, setWithdrawList] = useState([]);

  useEffect(() => {
    setWithdrawList([]);
    setPage(0);
    setHasNextPage(true);
  }, [UserStore.user.balance]);

  const initWithDrawList = async (page) => {
    try {
      setLoading(true);
      const withdraw = await getWithdrawalsList(page);
      setPage(page);
      setHasNextPage(page < withdraw?.pagination.totalPages);
      setWithdrawList((prevState) => {
        return [...prevState, ...withdraw.items];
      });
    } catch (e) {
      toast.error(t('somethingWentWrong'));
    } finally {
      setLoading(false);
    }
  };

  const handleLoadMore = async () => {
    await initWithDrawList(page + 1);
  };

  const [infiniteRef] = useInfiniteScroll({
    loading,
    onLoadMore: handleLoadMore,
    hasNextPage,
  });

  return (
    <div className="withdraw-table__wrapper">
      <table className="withdraw-table table">
        <thead>
          <tr>
            <th>{t('date')}</th>
            <th>{t('sum')}</th>
            <th>{t('status')}</th>
          </tr>
        </thead>
        <tbody>
          {withdrawList.map((withdraw) => {
            return <WithdrawTableItem key={withdraw.id} withdrawData={withdraw} />;
          })}
        </tbody>
      </table>
      {withdrawList.length === 0 && !hasNextPage && (
        <div className="withdraw-table__not-found">{t('nothingFound')}</div>
      )}
      {hasNextPage && <div ref={infiniteRef} />}
      {loading && (
        <div className="loader">
          <Spinner width={24} height={24} />
        </div>
      )}
    </div>
  );
};
export default observer(WithdrawTable);
