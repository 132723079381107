import React from 'react';
import PageInfoLayout from '../../../components/Layout/PageInfoLayout';
import MediaQuery from 'react-responsive';
import breakpoints from '../../../utils/breakpoints';
import { NavLink } from 'react-router-dom';
import Routes from '../../../routes';
import SVG from 'react-inlinesvg';
import IconArrow from '../../../assets/icon/icon-arrow-right.svg';
import FeedBackForm from './FeedBackForm';
import AppStore from '../../../store/AppStore';
import { useTranslation } from 'react-i18next';

const FeedBackFormPage = () => {
  const { t } = useTranslation();

  return (
    <PageInfoLayout className="feedback-form">
      <>
        <MediaQuery maxWidth={breakpoints['sm-max']}>
          <div className="pages-info-layout__button-back-mobile">
            <NavLink
              to={AppStore.loggedIn ? Routes.home : Routes.login}
              className="main-button icon"
            >
              <SVG className="rotate-arrow" src={IconArrow} width={13} />
              <span dangerouslySetInnerHTML={{ __html: t('backToMain') }} />
            </NavLink>
          </div>
        </MediaQuery>
        <h1 dangerouslySetInnerHTML={{ __html: t('feedbackTitle') }} />
        <MediaQuery minWidth={breakpoints['md-min']}>
          <div className="pages-info-layout__buttons">
            <NavLink
              to={AppStore.loggedIn ? Routes.home : Routes.login}
              className="main-button icon"
            >
              <SVG className="rotate-arrow" src={IconArrow} width={13} />
              <span dangerouslySetInnerHTML={{ __html: t('backToMain') }} />
            </NavLink>
          </div>
        </MediaQuery>
      </>
      <div className="feedback-form__wrapper-content">
        <div className="feedback-form__content">
          <h2 dangerouslySetInnerHTML={{ __html: t('feedbackSubTitle') }} />
          <FeedBackForm />
        </div>
        <MediaQuery maxWidth={breakpoints['sm-max']}>
          <div className="pages-info-layout__button-back-mobile">
            <NavLink
              to={AppStore.loggedIn ? Routes.home : Routes.login}
              className="main-button icon"
            >
              <SVG className="rotate-arrow" src={IconArrow} width={13} />
              <span dangerouslySetInnerHTML={{ __html: t('backToMain') }} />
            </NavLink>
          </div>
        </MediaQuery>
      </div>
    </PageInfoLayout>
  );
};
export default FeedBackFormPage;
