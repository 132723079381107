import React from 'react';
import { animated } from 'react-spring';

const GirlParalax = ({ props }) => {
  const slowMotion = (x, y) => `translate3d(${x / 20}px,${y / 20}px,0)`;
  const middleMotion = (x, y) => `translate3d(${x / 15}px,${y / 10}px,0)`;
  const bigMotion = (x, y) => `translate3d(${x / 10}px,${y / 5}px,0)`;
  return (
    <div className="girl-paralax">
      <img className="girl-paralax__girl-img" src="/images/girl.png" alt="" />

      <animated.div
        className="phone fade-in-up"
        style={{ transform: props.xy.interpolate(middleMotion) }}
      >
        <img src="/images/paralax/Phone.png" alt="" />
      </animated.div>
      <animated.div
        className="bag fade-in-up"
        style={{ transform: props.xy.interpolate(bigMotion) }}
      >
        <img src="/images/paralax/Bag.png" alt="" />
      </animated.div>
      <animated.div
        className="shoe fade-in-up"
        style={{ transform: props.xy.interpolate(bigMotion) }}
      >
        <img src="/images/paralax/Shoe.png" alt="" />
      </animated.div>
      <animated.div
        className="back-shoe fade-in-up"
        style={{ transform: props.xy.interpolate(middleMotion) }}
      >
        <img src="/images/paralax/BackShoe.png" alt="" />
      </animated.div>
      <animated.div
        className="pod-o fade-in-up"
        style={{ transform: props.xy.interpolate(bigMotion) }}
      >
        <img src="/images/paralax/Pod1.png" alt="" />
      </animated.div>
      <animated.div
        className="pod-t fade-in-up"
        style={{ transform: props.xy.interpolate(bigMotion) }}
      >
        <img src="/images/paralax/Pod2.png" alt="" />
      </animated.div>
      <animated.div
        className="lipstick-o fade-in-up"
        style={{ transform: props.xy.interpolate(bigMotion) }}
      >
        <img src="/images/paralax/Lipstick1.png" alt="" />
      </animated.div>
      <animated.div
        className="lipstick-t fade-in-up"
        style={{ transform: props.xy.interpolate(bigMotion) }}
      >
        <img src="/images/paralax/Lipstick2.png" alt="" />
      </animated.div>
      <animated.div
        className="watch fade-in-up"
        style={{ transform: props.xy.interpolate(middleMotion) }}
      >
        <img src="/images/paralax/Watch.png" alt="" />
      </animated.div>
      <animated.div
        className="particle-on fade-in-down"
        style={{ transform: props.xy.interpolate(slowMotion) }}
      >
        <img src="/images/paralax/Particle01.png" alt="" />
      </animated.div>
      <animated.div
        className="particle-tw fade-in-down"
        style={{ transform: props.xy.interpolate(slowMotion) }}
      >
        <img src="/images/paralax/Particle02.png" alt="" />
      </animated.div>
      <animated.div
        className="particle-th fade-in-down"
        style={{ transform: props.xy.interpolate(slowMotion) }}
      >
        <img src="/images/paralax/Particle03.png" alt="" />
      </animated.div>
      <animated.div
        className="particle-fo fade-in-down"
        style={{ transform: props.xy.interpolate(slowMotion) }}
      >
        <img src="/images/paralax/Particle04.png" alt="" />
      </animated.div>
      <animated.div
        className="particle-fi fade-in-down"
        style={{ transform: props.xy.interpolate(slowMotion) }}
      >
        <img src="/images/paralax/Particle05.png" alt="" />
      </animated.div>
      <animated.div
        className="particle-si fade-in-down"
        style={{ transform: props.xy.interpolate(slowMotion) }}
      >
        <img src="/images/paralax/Particle06.png" alt="" />
      </animated.div>
      <animated.div
        className="particle-se fade-in-down"
        style={{ transform: props.xy.interpolate(slowMotion) }}
      >
        <img src="/images/paralax/Particle07.png" alt="" />
      </animated.div>
      <animated.div
        className="particle-ei fade-in-down"
        style={{ transform: props.xy.interpolate(slowMotion) }}
      >
        <img src="/images/paralax/Particle08.png" alt="" />
      </animated.div>
      <animated.div
        className="particle-ni fade-in-down"
        style={{ transform: props.xy.interpolate(slowMotion) }}
      >
        <img src="/images/paralax/Particle09.png" alt="" />
      </animated.div>
      <animated.div
        className="particle-te fade-in-down"
        style={{ transform: props.xy.interpolate(slowMotion) }}
      >
        <img src="/images/paralax/Particle10.png" alt="" />
      </animated.div>
      <animated.div
        className="particle-o-el fade-in-down"
        style={{ transform: props.xy.interpolate(slowMotion) }}
      >
        <img src="/images/paralax/Particle11.png" alt="" />
      </animated.div>
      <animated.div
        className="particle-o-tw fade-in-down"
        style={{ transform: props.xy.interpolate(slowMotion) }}
      >
        <img src="/images/paralax/Particle12.png" alt="" />
      </animated.div>
      <animated.div
        className="particle-o-th fade-in-down"
        style={{ transform: props.xy.interpolate(slowMotion) }}
      >
        <img src="/images/paralax/Particle13.png" alt="" />
      </animated.div>
      <animated.div
        className="particle-o-fo fade-in-down"
        style={{ transform: props.xy.interpolate(slowMotion) }}
      >
        <img src="/images/paralax/Particle14.png" alt="" />
      </animated.div>
      <animated.div
        className="particle-o-fi fade-in-down"
        style={{ transform: props.xy.interpolate(slowMotion) }}
      >
        <img src="/images/paralax/Particle15.png" alt="" />
      </animated.div>
      <animated.div
        className="particle-o-si fade-in-down"
        style={{ transform: props.xy.interpolate(slowMotion) }}
      >
        <img src="/images/paralax/Particle16.png" alt="" />
      </animated.div>
      <animated.div
        className="particle-o-se fade-in-down"
        style={{ transform: props.xy.interpolate(slowMotion) }}
      >
        <img src="/images/paralax/Particle17.png" alt="" />
      </animated.div>
      <animated.div
        className="particle-o-ei fade-in-down"
        style={{ transform: props.xy.interpolate(slowMotion) }}
      >
        <img src="/images/paralax/Particle18.png" alt="" />
      </animated.div>
      <animated.div
        className="particle-o-ni fade-in-down"
        style={{ transform: props.xy.interpolate(slowMotion) }}
      >
        <img src="/images/paralax/Particle19.png" alt="" />
      </animated.div>
      <animated.div
        className="particle-o-twe fade-in-down"
        style={{ transform: props.xy.interpolate(slowMotion) }}
      >
        <img src="/images/paralax/Particle20.png" alt="" />
      </animated.div>
      <animated.div
        className="particle-o-tw-on fade-in-down"
        style={{ transform: props.xy.interpolate(slowMotion) }}
      >
        <img src="/images/paralax/Particle21.png" alt="" />
      </animated.div>
      <animated.div
        className="particle-o-tw-tw fade-in-down"
        style={{ transform: props.xy.interpolate(slowMotion) }}
      >
        <img src="/images/paralax/Particle22.png" alt="" />
      </animated.div>
      <animated.div
        className="particle-o-tw-th fade-in-down"
        style={{ transform: props.xy.interpolate(slowMotion) }}
      >
        <img src="/images/paralax/Particle23.png" alt="" />
      </animated.div>
      <animated.div
        className="particle-o-tw-fo fade-in-down"
        style={{ transform: props.xy.interpolate(slowMotion) }}
      >
        <img src="/images/paralax/Particle24.png" alt="" />
      </animated.div>
    </div>
  );
};
export default GirlParalax;
