export default {
  translation: {
    authorization: {
      email: 'E-mail',
      password: 'Password',
      confirmPassword: 'Password confirmation',
      errorsEmptyInput: 'Please fill in to continue',
      noValidEmail: 'Wrong e-mail format',
      noValidPassword: 'Password must contain more than 3 symbols.',
      coincidePassword: 'Passwords must match',
      completeRegistration: 'Complete registration',
      login: 'Sign in',
      backToLogin: 'Back to login page',
      register: 'Registration',
      forgotPassword: 'Forgot your password?',
      haveAccount: 'Have an account?',
    },
    menu: {
      home: 'Main page',
      infoUser: 'User data',
      profile: 'User profile',
      statistics: 'My statistics',
      myBalance: 'My balance',
      logOut: 'Sign out',
      products: 'Products',
    },
    footer: {
      howWorks: 'How it works',
      faq: 'F.A.Q.',
      about: 'About the company',
      contact: 'Contacts',
      feedback: 'Contact us',
      dmca: 'DMCA',
      policy: 'Privacy Policy',
      terms: 'User Agreement',
    },
    others: {
      notFound: 'Page not found',
    },
    nicknameUser: 'User Nickname',
    nickname: 'nickname',
    fill: 'Fill in',
    cannotEarn: 'You can’t make profit until user data is filled in!',
    acceptPolicy: 'I agree with %privacy%',
    saveChanges: 'Save changes',
    bestWeekOffers: 'This week’s best offers',
    linkCopied: 'Link has been copied to clipboard',
    getLink: 'Get a link',
    loadMore: 'Show more',
    price: 'Price',
    bonus: 'Bonus',
    somethingWentWrong: 'Something went wrong',
    needToFillNickname: 'To see the list of products, fill in the nickname field',
    withdrawFunds: 'Withdraw funds',
    availableForWithdrawal: 'Available for withdrawal',
    withdraw: 'Withdraw',
    withdrawalOfFounds: 'Withdraw funds',
    withdrawalAmount: 'Withdrawal amount',
    withdrawalRequests: 'Withdrawal requests',
    incorrectSum: 'Withdrawal amount must exceed 0 and be smaller than the balance amount',
    withdrawalSuccess: 'Withdrawal request has been successfully created',
    seeStatus: 'Check status',
    date: 'Data',
    sum: 'Amount',
    status: 'Status',
    nothingFound: 'Nothing has been found',
    active: 'Active',
    approved: 'Approved',
    rejected: 'Canceled',
    change: 'Changes',
    balance: 'Balance',
    changingYourBalance: 'Changes in your balance',
    currency: 'Currency',
    yourBalance: 'Your balance',
    lastName: 'Surname',
    name: 'Name',
    surname: 'Patronym',
    contactDetails: 'Contact data',
    country: 'Country',
    city: 'City',
    address: 'Address',
    phone: 'Phone number',
    userProfile: 'User data',
    profileImage: 'User’s images',
    upload: 'Upload',
    userPassword: 'User’s password',
    changePassword: 'New password',
    timeZone: 'Time zone',
    select: 'Select',
    selectPeriod: 'Select period',
    earnEvenMore: 'Make even more profit',
    getUpTo: 'Get up to 60% of the cost of the gifts of your fans',
    tellMeMore: 'Tell me More',
    howEarnMore: '<b>How</b> PayFan helps you to boost your profit',
    ifHaveFans: 'If you have fans, you can earn <b>up to 60% of the cost of their gifts!</b>',
    toDoThis: 'Just follow these steps:',
    step: 'Step',
    step1text:
      'Complete a simple registration on our website and fill out your profile as it’s shown in the screenshot.',
    step2text: 'Select the gift you like, copy a link to the gift and send it to your fan.',
    step3text:
      'When a fan places an order on the partner’s website, he or she will select you as a gift recipient. <b>Your fan won’t have to enter your address or any other information to place an order. Thus, your personal data will be safe!</b>',
    step4text:
      'You’ll immediately receive a payout in your personal account – it will be equal up to 60% of the gift’s cost. Then, you’ll be able to withdraw these funds to your card!',
    step5text: 'Thus, you’ll receive a gift and up to 60% of its cost credited to your bank card!',
    faqTitle: '<b>F.A.Q.</b> and Support',
    backToMain: 'Back to main page',
    faqPageContent:
      '<h2>How does it work?</h2>\n' +
      '<div>Your fans want to do something nice for you and give you a gift, but you don’t want them to know your address because of security issues? PayFan is a perfect solution. Your address will always be stored within the system. You send your fan a link to the gift you chose, your fan buys it, delivery service delivers it to the address you specified.</div>\n' +
      '<h2>How much cashback can I get?</h2>\n' +
      '<div>In the beginning, standard cashback is 10% of gift cost. The percentage rate will increase as sales grow.</div>\n' +
      '<h2>What beauty products do you sell?</h2>\n' +
      '<div>This is a new premium cosmetics brand. Our products are manufactured in an unpolluted area in Italy – we use only environmentally friendly raw materials, control all stages of cosmetics production and never test neither ingredients nor ready-made products on animals. We work to make your wish to change eye-catching looks while staying yourself come true. We pay heed to what you want from cosmetics and think about you when working on new products.</div>\n' +
      '<h2>What does your logo symbolize?</h2>\n' +
      '<div>It’s simply a heart, it symbolizes love – our love to our clients with which we create products and the love of the fans who decide to give a gift.</div>',
    feedbackTitle: '<b>Feedback</b> Form',
    feedbackSubTitle: 'If you have any questions, don’t hesitate to ask us <b>at any time!</b>',
    fillContactForm: 'Just fill in the contact form:',
    yourName: 'Your name...',
    yourEmail: 'Your Email...',
    howWeCanHelp: 'How can we help you?',
    submit: 'Send',
    privacyTitle: '<b>Privacy</b> Policy',
    privacyContent:
      '<h2>1. What is Privacy Policy?</h2>\n' +
      '<p>Basic privacy policy describes a relationship between a website and its users’ personal information.</p>\n' +
      '<p>The first step towards creation of a compatible and comprehensive privacy policy is understanding what privacy policy is.</p>\n' +
      '<h3>Privacy policy definition</h3>\n' +
      '<p>Privacy policy is a legal paper that informs the users of your website about how you collect and process their personal information. Privacy policy can also have the following names:</p>\n' +
      '<ul>\n' +
      '<li>Privacy notice </li>\n' +
      '<li>Privacy policy statement</li>\n' +
      '<li>Privacy page</li>\n' +
      '</ul>\n' +
      '<h3>Standard privacy policy for a website</h3>\n' +
      '<p>We’ll talk about details later, in the <b classname="color">«What to add to Boilerplate Privacy policy»</b> section. Basic privacy policy describes the following things.</p>\n' +
      '<h2>2. Is Privacy Policy required by law?</h2>\n' +
      '<p>If your website uses personal information (for example, names, e-mail, information about a bank card), most legislations all over the world require you to have Privacy Policy.</p>\n' +
      '<p>If you release<b>a mobile or desktop app or launch a website</b>, most likely, legislation requires you to have Privacy Policy on your website. You must provide clear links to your Privacy Policy in a visible place to let the users access it easily and quickly.</p>',
    authorizationError: 'Authorization error, please sign in again',
    avatarUploaded: 'Profile picture has been uploaded',
    delete: 'Remove',
    alreadyInUse: 'This nickname is already used',
    today: 'Today',
    week: 'Week',
    month: 'Month',
    forAllTime: 'All time',
    clearSelectedPeriod: 'Clear selected period',
    confirm: 'Confirm',
    loginError: 'Login failed: wrong login or password',
    regError: 'Email is already used',
    selectCountry: 'Select country',
    invalidPhone: 'Wrong phone number',
    nicknameInvalid: 'You can use only Latin letters and digits',
    productList: 'Product list',
    payer: 'Payer',
    dataChangedSuccess: 'Data has been successfully changed',
    confirmAndContinue: 'Confirm and continue',
    cookieText:
      'This website uses cookie files to improve your gaming experience. Please accept all cookie files or change cookie settings.',
    advanced: 'Advanced settings',
    acceptTheSelected: 'Accept selected cookie files',
    cookieMore: 'More details on cookie files',
    mandatory: 'Required',
    optionMandatory: 'Functional and required cookie files must be turned on',
    statistical: 'Statistical',
    optionStatistical: 'Needed to improve website operation and service provision',
    socialMedia: 'Social network',
    optionSocialMedia: 'Needed to exchange content in social networks',
    productSubTitle:
      'Hi, amazing beauty! Choose the gift you want to receive, copy a link and send it to your fan. The more your fans buy, the more profit you make!',
    AboutUsTitle: 'About <b>us</b>',
    AboutUsContent:
      '<p>\n' +
      'Your fans want to buy you a gift, but it’s not safe to give them your address… Your fans can’t choose what you really want… <br />\n' +
      'It often happens that several people want to give someone the same gift. Are you sure you need, for example, 5 similar lipsticks?\n' +
      '</p>\n' +
      '<p>\n' +
      'РayFan is a platform allowing not only to receive the desired gift\n' +
      'from a fan, but also to make extra money on it.\n' +
      '<br />\n' +
      'PayFan – is safe, profitable and convenient.\n' +
      '</p>\n' +
      '<p>\n' +
      'Complete your profile on the website, specify delivery address and choose the gifts\n' +
      'from our partners that you like. Every gift is provided with its own unique link – give it to your fan and wait for delivery! You will get not only a gift, but also percentage\n' +
      'of sales,\n' +
      'that will be saved on your personal account. It’s a double pleasure. Only delivery service courier can see your address. This person doesn’t know who you are\n' +
      'and whose gift he or she delivers.\n' +
      '</p>',
    latteShopInfo:
      '<p>\n' +
      'We came up with the idea to create LATTE BEAUTY in 2018 and released the first products in 2019. Now, we offer 70 products and always make sure each of them is not only trendy, but also high-quality and useful.\n' +
      '</p>\n' +
      '<p>\n' +
      'We use only environmentally-friendly raw materials and control\n' +
      'all stages of cosmetics production. The company derives inspiration from current beauty trends and, of course, your reviews to create new products.\n' +
      'As a result —\n' +
      'you receive the cosmetics that meets your needs and delivers quick & visible effects, taking care of your health at the same time.\n' +
      '</p>\n' +
      '<p>\n' +
      'No matter how old you are, the best beauty products are the ones that highlight your unique beauty and improve your self-confidence. This is why our catalogue always can surprise you with something new and interesting, able to emphasize your features.\n' +
      '</p>\n' +
      '<p>\n' +
      'Our products are developed in collaboration with the best cosmetics experts,\n' +
      'cruelty-free, manufactured from high-quality ingredients and\n' +
      'with the help of the company’s equipment, and meets GMP standards.\n' +
      '</p>',
    vittedyShopInfo:
      '<h3>VITEDDY</h3>\n' +
      '<p>\n' +
      'VITEDDY is about daily transformation and care, unique formulas, cutting-edge technologies and natural ingredients for your beauty.\n' +
      '</p>\n' +
      '<p>\n' +
      'VITEDDY brand concept is based on creation of multifunctional, effective and affordable cosmetics solutions. Quality and functionality, as well as a quick and visible effect that makes your skin healthy and beautiful and brings happiness to your life are the main goals.\n' +
      '</p>\n' +
      '<p>\n' +
      'Our experts have many years of experience with the world’s best equipment, this is why Viteddy can use unique technologies\n' +
      'and formulas. Focus-group testing helps to reveal the general needs of the women with different skin and hair types and select the optimal formulas able to solve several problems.\n' +
      '</p>\n' +
      '<p>\n' +
      'VITEDDY has its own development laboratory and uses modern experience and technologies to create cosmetics products. Cooperation with independent tech experts and international professionals of beauty industry makes our products not only modern, but also very effective.\n' +
      '</p>\n' +
      '<p>\n' +
      'In November, 2018, we presented 4 cosmetics products – this is how VITEDDY products went on sale. They got a lot of positive reviews very soon and became a must for many popular beauty bloggers and clients.\n' +
      '</p>\n' +
      '<p>\n' +
      'The brand constantly expands the range of products it offers. The full line of scalp and hair care products developed by a team of trichologists and\n' +
      'hair stylists was released in the spring of 2019. It’s based on the modern woman’s requirements to hair care.\n' +
      '</p>\n' +
      '<p>\n' +
      'The company enters new markets and forges partnership with major markets. You can buy our products on our website (worldwide delivery available) and on online platforms, major markets, in beauty salons and via cosmetics networks.\n' +
      '</p>\n' +
      '<p>What’s in there? </p>\n' +
      '<p>\n' +
      "Cosmetics formulas are a combination of nourishing oils and essential vitamins thanks to which your hair, nails and skin will become silky and shiny very soon. You'll glow with health and beauty every day.\n" +
      'Be sure you’ll fall in love with your new reflection in the mirror.\n' +
      '</p>',
    partners: 'Partners',
    ourPartners: 'Our Partners',
    officialDistributor: 'Official distributor',
    contactPhoneValue: '+356 203 41 583',
    contactAddressValue:
      "Darix Limited, Elite Business Centre, Trejqa ta' Box Box, Msida MSD 1840, Malta. Site: darix.com. 北京得乐贸易有限公司 中国 北京市朝阳区汤立路218号7层866 北京 直轄市 100012",
    contactDistributorValue: 'Darix Beauty SL <br/>  PASEO DE GRACIA 1208007 BARCELONA, Spain',
    contactEmailValue: 'customerservice@lattebeauty.com',
    questionSent: 'Question sent successfully',
  },
};
