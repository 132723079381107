import React from 'react';
import { NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import IconQuestion from '../../assets/icon/icon-question.svg';
import { FooterList } from '../../utils/FooterList';

const FooterMain = ({ className }) => {
  const footerList = FooterList();
  return (
    <div className={`footer-main ${className || ''}`}>
      <button className="footer-main__button-support">
        <SVG src={IconQuestion} width={30} />
        Support Chat 24/7
      </button>
      <div className="footer-main__menu">
        {footerList.firstMenu.map((menu, i) => {
          return (
            <NavLink className="footer-main__link" exact to={menu.route} key={i}>
              {menu.label}
            </NavLink>
          );
        })}
      </div>
      <div className="footer-main__menu">
        {footerList.secondMenu.map((menu, i) => {
          return (
            <NavLink className="footer-main__link" exact to={menu.route} key={i}>
              {menu.label}
            </NavLink>
          );
        })}
        <div className="footer-main__link _year">© 2022.PayFan</div>
      </div>
    </div>
  );
};
export default FooterMain;
