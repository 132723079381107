export default {
  'xs-max': '576px',
  'sm-min': '577px',
  'sm-max': '768px',
  'md-min': '769px',
  'md-max': '992px',
  'lg-min': '993px',
  'lg-max': '1200px',
  'xl-min': '1201px',
  'xl-max': '1400px',
};
