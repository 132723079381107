import React from 'react';
import colorsVars from '../../utils/colorsVars';

const Spinner = ({
  width = 20,
  height = 20,
  borderWidth = 4,
  color = colorsVars.colorAccent,
  center,
}) => {
  return (
    <div
      style={{
        width: `${width}px`,
        height: `${height}px`,
      }}
      className={`spinner ${center ? 'spinner_center' : ''}`}
    >
      <span
        style={{
          width: `${width}px`,
          height: `${height}px`,
          borderColor: `${color} transparent ${color} transparent`,
          borderWidth: `${borderWidth}px`,
        }}
      />
    </div>
  );
};

export default Spinner;
