import React, { useState } from 'react';
import GetShortLinkButton from './GetShortLinkButton';
import { useTranslation } from 'react-i18next';
import Modal from 'react-responsive-modal';
import breakpoints from '../../utils/breakpoints';
import MediaQuery from 'react-responsive';

const ShopsListItemProduct = ({ productData }) => {
  const [showProductModal, setShowProductModal] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <div
        className="product-item"
        onClick={() => {
          setShowProductModal(true);
        }}
      >
        <div className="product-item__name">{productData.name}</div>
        <div className="product-item__bottom">
          <div className="product-item__image-container">
            <img src={productData.photo} alt="" />
          </div>
          <div className="product-item__price">
            {t('price')}:&nbsp;
            <span>
              {productData.price?.amount}&nbsp;
              {productData.price.currency?.isoCode}
            </span>{' '}
            ({t('bonus')}:&nbsp;
            <span>
              {productData.bonus?.amount}&nbsp;
              {productData.bonus?.currency?.isoCode}
            </span>
            )
          </div>
          <GetShortLinkButton
            defaultLink={productData.fanUrl}
            siteId={productData.ccTargetSiteId}
          />
        </div>
      </div>
      <Modal
        center
        onClose={() => {
          setShowProductModal(false);
        }}
        open={showProductModal}
      >
        <div className="product-item__modal">
          <MediaQuery minWidth={breakpoints['sm-min']}>
            <div className="product-item__modal-image">
              <img src={productData.photo} alt="" />
            </div>
          </MediaQuery>
          <div className="product-item__modal-text">
            <div className="product-item__modal-name">{productData.name}</div>
            <MediaQuery maxWidth={breakpoints['xs-max']}>
              <div className="product-item__modal-image">
                <img src={productData.photo} alt="" />
              </div>
            </MediaQuery>
            <div className="product-item__modal-price">
              {t('price')}:&nbsp;
              <span>
                {productData.price?.amount}&nbsp;
                {productData.price.currency?.isoCode}
              </span>{' '}
              ({t('bonus')}:&nbsp;
              <span>
                {productData.bonus?.amount}&nbsp;
                {productData.bonus?.currency?.isoCode}
              </span>
              )
            </div>
            <div
              className="product-item__modal-description"
              dangerouslySetInnerHTML={{ __html: productData.description }}
            />
            <GetShortLinkButton
              defaultLink={productData.fanUrl}
              siteId={productData.ccTargetSiteId}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ShopsListItemProduct;
