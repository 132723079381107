export default {
  login: '/login',
  home: '/',
  profile: '/profile',
  userInfo: '/user-info',
  statistics: '/statistics',
  balance: '/balance',
  support: '/support',
  howIsWork: '/how-it-works',
  policy: '/policy',
  feedBackForm: '/feedback-form',
  faq: '/faq',
  about: '/about',
  contact: '/contact',
  dmca: '/dmca',
  terms: '/terms',
  products: '/products',
  withdraw: '/withdraw',
  notFound: '',
};
