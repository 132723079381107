import React from 'react';
import { observer } from 'mobx-react-lite';
import SingUpForm from './SingUpForm';
import CountrySwitcher from '../../components/CountrySwitcher';
import Logo from '../../components/Base/Logo';
import SVG from 'react-inlinesvg';
import IconDots from '../../assets/icon/icon-dots.svg';
import { NavLink } from 'react-router-dom';
import GirlParalax from './GirlParalax';
import { useSpring } from 'react-spring';
import MediaQuery from 'react-responsive';
import breakpoints from '../../utils/breakpoints';
import { FooterList } from '../../utils/FooterList';
import { useTranslation } from 'react-i18next';
import Routes from '../../routes';

const SignUpPage = () => {
  const { t } = useTranslation();
  const footerList = FooterList();
  const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));
  return (
    <div
      className="sign-up-page"
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
    >
      <div className="sign-up-page__more">
        <div className="pages-info-layout__menu">
          <Logo />
          <CountrySwitcher />
        </div>
        <div className="sign-up-page__earn-more">
          <h1>{t('earnEvenMore')}</h1>
          <h2>{t('getUpTo')}</h2>
          <NavLink exact to={Routes.howIsWork} className="main-button button-light icon">
            {t('tellMeMore')}
            <SVG src={IconDots} width={20} />
          </NavLink>
          <div />
        </div>
        <MediaQuery maxWidth={breakpoints['md-max']}>
          <div className="sign-up-page__girl-mobile">
            <img src="/images/girl-mobile.png" alt="" />
          </div>
        </MediaQuery>
        <MediaQuery minWidth={breakpoints['md-max']}>
          <GirlParalax props={props} />
          <div className="sign-up-page__footer-menu">
            {footerList.firstMenu.map((menu, i) => {
              return (
                <NavLink className="sign-up-page__footer-link" exact to={menu.route} key={i}>
                  {menu.label}
                </NavLink>
              );
            })}
          </div>
        </MediaQuery>
      </div>
      <div className="sign-up-page__auth">
        <MediaQuery minWidth={breakpoints['md-max']}>
          <div className="sign-up-page__menu">
            {/* <button className="sign-up-page__button-support">
              <SVG src={IconQuestion} width={20} />
              Support Chat 24/7
            </button> */}
          </div>
        </MediaQuery>
        <div className="sign-up-page__wrapper-form">
          <SingUpForm />
        </div>
        <MediaQuery maxWidth={breakpoints['md-max']}>
          <div className="sign-up-page__menu">
            {/* <button className="sign-up-page__button-support">
              <SVG src={IconQuestion} width={20} />
              Support Chat 24/7
            </button> */}
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={breakpoints['md-max']}>
          <div className="sign-up-page__footer-menu">
            {footerList.firstMenu.map((menu, i) => {
              return (
                <NavLink className="sign-up-page__footer-link" exact to={menu.route} key={i}>
                  {menu.label}
                </NavLink>
              );
            })}
          </div>
        </MediaQuery>
        <div className="sign-up-page__footer-menu">
          {footerList.secondMenu.map((menu, i) => {
            return (
              <NavLink className="sign-up-page__footer-link" exact to={menu.route} key={i}>
                {menu.label}
              </NavLink>
            );
          })}
          <div className="sign-up-page__footer-year">© 2022.PayFan</div>
        </div>
      </div>
    </div>
  );
};

export default observer(SignUpPage);
