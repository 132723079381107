import React, { useEffect, useState } from 'react';
import ShopsListItemShop from './ShopsListItemShop';
import ShopsListItemProduct from './ShopsListItemProduct';
import { getProducts } from '../../api/actions/shop-list';
import { useTranslation } from 'react-i18next';
import Spinner from '../Base/Spinner';
import colorsVars from '../../utils/colorsVars';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';
import breakpoints from '../../utils/breakpoints';

const ShopsList = ({ shop }) => {
  const { i18n, t } = useTranslation();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [savedLimit, setSavedLimit] = useState(0);
  const breakpointLG = useMediaQuery({
    query: `(max-width: ${breakpoints['xl-max']}) and (min-width: ${breakpoints['lg-min']})`,
  });
  const [productsList, setProductsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(false);

  useEffect(() => {
    setProductsList([]);
    initProductList(1, true);
  }, [i18n.language]);

  const initProductList = async (page, firstLoading) => {
    try {
      setLoading(true);
      const limit = breakpointLG ? 3 : 4;
      firstLoading && setFirstLoading(true);
      firstLoading && setSavedLimit(breakpointLG ? 3 : 4);
      const res = await getProducts(page, savedLimit || limit, i18n.language);
      const listData = res.items;
      const pagination = res.pagination;
      setPage(pagination.currentPage);
      setTotalPages(pagination.totalPages);
      setProductsList((prevState) => {
        return [...prevState, ...listData];
      });
    } catch (e) {
      toast.error(t('somethingWentWrong'));
    } finally {
      setFirstLoading(false);
      setLoading(false);
    }
  };

  return (
    <div className="shop-list__container">
      <div
        className={`shop-list ${
          page !== totalPages && savedLimit > 1 ? 'shop-list__hide-last' : ''
        }`}
      >
        <ShopsListItemShop
          shop={shop}
          shopId={(productsList.length && productsList[0].ccTargetSiteId) || shop.id}
        />
        {firstLoading && (
          <div className="shop-list__loader">
            <Spinner width={30} height={30} />
          </div>
        )}
        {productsList.map((product) => {
          return <ShopsListItemProduct productData={product} key={product.remoteId} />;
        })}
      </div>
      {page < totalPages && (
        <div className="shop-list__btn-container">
          <button
            disabled={loading}
            onClick={() => {
              initProductList(page + 1);
            }}
            className={`shop-list__load-more-btn main-button button-accent ${
              loading ? 'shop-list__load-more-btn_loading' : ''
            }`}
          >
            <span>{t('loadMore')}</span>
            {loading && (
              <div className="shop-list__btn-loader">
                <Spinner width={20} height={20} borderWidth={2} color={colorsVars.colorLight} />
              </div>
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default ShopsList;
