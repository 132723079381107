import React from 'react';
import PropTypes from 'prop-types';

const DefaultInput = ({
  value,
  name,
  label = '',
  required = false,
  withoutSpaces = false,
  onChange,
  error,
  placeholder = '...',
  type = 'text',
  autoComplete = 'off',
  containerClassName,
  inputClassName,
}) => {
  return (
    <div className={`default-input ${containerClassName || ''}`}>
      {label && (
        <label className="label">
          {label}
          {required && '*'}
        </label>
      )}
      <input
        type={type}
        placeholder={placeholder}
        name={name}
        value={value}
        autoComplete={autoComplete}
        onChange={(event) => onChange(name, withoutSpaces, event)}
        className={`input nickname__form-input
                 ${error ? 'no-valid-input' : ''} ${inputClassName || ''}`}
      />
      {error && <div className="input_error">{error}</div>}
    </div>
  );
};

DefaultInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DefaultInput;
