import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import IconDots from '../../assets/icon/icon-dots.svg';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import UserStore from '../../store/UserStore';

const StatisticsTable = ({ date }) => {
  const [hasNextPage, setHasNextPage] = useState(true);
  const [loading, setLoading] = useState(false);
  const [partNumber, setPartNumber] = useState(1);
  const [statInfo, setStatInfo] = useState([]);
  const [statQueryDate, setStatQueryDate] = useState({
    partNumber,
    partSize: 10,
    from: '',
    to: '',
  });
  const setFormatDate = (date) => {
    const zeroPad = (input, length) => {
      return (Array(length + 1).join('0') + input).slice(-length);
    };
    if (date) {
      const year = new Date(date).getFullYear();
      const month = new Date(date).getMonth() + 1;
      const day = new Date(date).getDate();
      return `${year}-${zeroPad(month, 2)}-${zeroPad(day, 2)}`;
    }
    return '';
  };
  const handleLoadMore = () => {
    console.log(statInfo);
    setPartNumber(partNumber + 1);
    setHasNextPage(false);
    setLoading(false);
  };
  const [infiniteRef] = useInfiniteScroll({
    loading,
    onLoadMore: handleLoadMore,
    hasNextPage,
  });

  const [open, setOpen] = useState(false);
  const tableInfo = [
    {
      id: '1',
      date: '23.11.2021',
      admission: 45,
      summ: 480,
    },
    {
      id: '2',
      date: '20.11.2021',
      admission: -12,
      summ: 0,
    },
    {
      id: '3',
      date: '19.11.2021',
      admission: 25,
      summ: 250,
    },
    {
      id: '1',
      date: '23.11.2021',
      admission: 45,
      summ: 480,
    },
    {
      id: '2',
      date: '20.11.2021',
      admission: -12,
      summ: 0,
    },
    {
      id: '3',
      date: '19.11.2021',
      admission: 25,
      summ: 250,
    },
    {
      id: '1',
      date: '23.11.2021',
      admission: 45,
      summ: 480,
    },
    {
      id: '2',
      date: '20.11.2021',
      admission: -12,
      summ: 0,
    },
    {
      id: '3',
      date: '19.11.2021',
      admission: 25,
      summ: 250,
    },
    {
      id: '1',
      date: '23.11.2021',
      admission: 45,
      summ: 480,
    },
    {
      id: '2',
      date: '20.11.2021',
      admission: -12,
      summ: 0,
    },
    {
      id: '3',
      date: '19.11.2021',
      admission: 25,
      summ: 250,
    },
    {
      id: '1',
      date: '23.11.2021',
      admission: 45,
      summ: 480,
    },
    {
      id: '2',
      date: '20.11.2021',
      admission: -12,
      summ: 0,
    },
    {
      id: '3',
      date: '19.11.2021',
      admission: 25,
      summ: 250,
    },
    {
      id: '2',
      date: '20.11.2021',
      admission: -12,
      summ: 0,
    },
    {
      id: '3',
      date: '19.11.2021',
      admission: 25,
      summ: 250,
    },
    {
      id: '1',
      date: '23.11.2021',
      admission: 45,
      summ: 480,
    },
    {
      id: '2',
      date: '20.11.2021',
      admission: -12,
      summ: 0,
    },
    {
      id: '3',
      date: '19.11.2021',
      admission: 25,
      summ: 250,
    },
  ];
  const openDetail = (id) => {
    console.log(id);
    setOpen(true);
    // eslint-disable-next-line no-alert
  };
  const closeModal = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (date !== null) {
      setStatQueryDate((prevState) => {
        const localValues = {
          ...prevState,
          from: setFormatDate(date.dateStart),
          to: setFormatDate(date.dateEnd),
        };
        UserStore.getStatisticUser(localValues).then((statisticData) => {
          setStatInfo(statisticData);
        });
        return { ...localValues };
      });
    } else {
      UserStore.getStatisticUser(statQueryDate).then((statisticData) => {
        setStatInfo(statisticData);
      });
    }
  }, [date]);
  return (
    <div className="statistics-page__table-wrapper">
      <table className="statistics-page__table table">
        <thead>
          <tr>
            <th>Дата</th>
            <th>Поступление</th>
            <th>Сумма Подарков</th>
            <th className="statistics-page__table-details">Детально</th>
          </tr>
        </thead>
        <tbody>
          {tableInfo.map((el, i) => {
            return (
              <tr key={i}>
                <td>{el.date}</td>
                <td className={`${Math.sign(el.admission) === -1 ? 'minus' : 'plus'}`}>
                  {el.admission}
                </td>
                <td className="bold">{el.summ}</td>
                <td className="details" onClick={() => openDetail(el.id)}>
                  <SVG src={IconDots} width={20} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {hasNextPage && <div ref={infiniteRef} className="loader" />}
      <Modal open={open} onClose={closeModal} top>
        <div>Привет</div>
      </Modal>
    </div>
  );
};

export default StatisticsTable;
