import React, { useState, useEffect } from 'react';
import useRouter from '../../hooks/useRouter';
import { NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { MenuPageList } from '../../utils/MenuPageList';
import IconLogout from '../../assets/icon/icon-logout-menu.svg';
import IconArrow from '../../assets/icon/icon-arrow-right.svg';
import { useTranslation } from 'react-i18next';
import Routes from '../../routes';
import IconQuestion from '../../assets/icon/icon-question.svg';

const MobileSelectPage = ({ logOut }) => {
  const router = useRouter();
  const menuList = MenuPageList();
  const { t } = useTranslation();
  const NotFound = { route: Routes.notFound, label: t('others.notFound'), icon: IconQuestion };

  const [open, setOpen] = useState(false);

  const initialMenu = menuList.find((menu) => router.pathname === menu.route) || NotFound;

  const [selectedMenu, setSelectedMenu] = useState(initialMenu);

  const menuListWithoutSelected = menuList.filter((menu) => menu.route !== selectedMenu.route);

  const openMenu = () => {
    setOpen(!open);
  };
  const closeMenu = () => {
    setOpen(false);
  };

  useEffect(() => {
    setSelectedMenu(initialMenu);
    setOpen(false);
  }, [router.pathname, menuList[0].label]);
  return (
    <div className="mobile-select-page">
      {open && <div className="mobile-select-page__overlay" onClick={closeMenu} />}
      <div className="mobile-select-page__title">Личный кабинет</div>
      <div className="mobile-select-page__menu">
        <div className={`mobile-select-page__menu-list ${open ? 'active' : ''}`}>
          <div className="mobile-select-page__menu-item selected-menu" onClick={openMenu}>
            <div className="mobile-select-page__link">
              <span>
                <SVG src={selectedMenu.icon} width={14} />
                {selectedMenu.label}
              </span>
              <SVG className="arrow-right" src={IconArrow} width={6} />
            </div>
          </div>
          <div className={`mobile-select-page__menu-items ${open ? 'active' : ''}`}>
            {menuListWithoutSelected.map((menu, i) => {
              return (
                <div className="mobile-select-page__menu-item" key={i}>
                  <NavLink
                    exact
                    className="mobile-select-page__link"
                    activeClassName="mobile-select-page__link_active"
                    to={menu.route}
                  >
                    <SVG src={menu.icon} width={14} />
                    {menu.label}
                  </NavLink>
                </div>
              );
            })}
            <div className="mobile-select-page__menu-item">
              <button onClick={logOut} className="header-sidebar__link">
                <SVG src={IconLogout} width={14} />
                {t('menu.logOut')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MobileSelectPage;
