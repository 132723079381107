import React from 'react';
import PageInfoLayout from '../../../components/Layout/PageInfoLayout';
import SVG from 'react-inlinesvg';
import Routes from '../../../routes';
import IconArrow from '../../../assets/icon/icon-arrow-right.svg';
import IconPlus from '../../../assets/icon/icon-plus.svg';
import useRouter from '../../../hooks/useRouter';
import AppStore from '../../../store/AppStore';
import breakpoints from '../../../utils/breakpoints';
import MediaQuery from 'react-responsive';
import { useTranslation } from 'react-i18next';

const HowItWorks = () => {
  const { t } = useTranslation();
  const router = useRouter();

  const goToLogin = () => {
    AppStore.statusRegisterPage(false);
    router.push(Routes.login);
  };
  const goToRegister = () => {
    AppStore.statusRegisterPage(true);
    router.push(Routes.login);
  };

  return (
    <>
      <PageInfoLayout className="how-it-work-page">
        <>
          <h1 dangerouslySetInnerHTML={{ __html: t('howEarnMore') }} />
          {!AppStore.loggedIn && (
            <MediaQuery minWidth={breakpoints['md-min']}>
              <div className="pages-info-layout__buttons">
                <button onClick={goToLogin} className="main-button button-light icon">
                  {t('authorization.login')}
                  <SVG src={IconArrow} width={10} />
                </button>
                <button onClick={goToRegister} className="main-button  icon">
                  {t('authorization.register')}
                  <SVG src={IconPlus} width={13} />
                </button>
              </div>
            </MediaQuery>
          )}
        </>
        <div className="how-it-work-page__wrapper-content">
          <div className="how-it-work-page__content">
            <h2 dangerouslySetInnerHTML={{ __html: t('ifHaveFans') }} />

            <div className="how-it-work-page__list">
              <div className="how-it-work-page__list-title">{t('toDoThis')}</div>
              <div className="how-it-work-page__list-item">
                <div className="how-it-work-page__image-container">
                  <img src="/images/step1.png" alt="" />
                </div>
                <div className="how-it-work-page__list-item-text">
                  <span className="how-it-work-page__list-item-step">{t('step')} 1</span>
                  <span dangerouslySetInnerHTML={{ __html: t('step1text') }} />
                </div>
              </div>
              <div className="how-it-work-page__list-item">
                <div className="how-it-work-page__image-container">
                  <img src="/images/step2.png" alt="" />
                </div>
                <div className="how-it-work-page__list-item-text">
                  <span className="how-it-work-page__list-item-step">{t('step')} 2</span>
                  <span dangerouslySetInnerHTML={{ __html: t('step2text') }} />
                </div>
              </div>
              <div className="how-it-work-page__list-item">
                <div className="how-it-work-page__image-container">
                  <img src="/images/step3.png" alt="" />
                </div>
                <div className="how-it-work-page__list-item-text">
                  <span className="how-it-work-page__list-item-step">{t('step')} 3</span>
                  <span dangerouslySetInnerHTML={{ __html: t('step3text') }} />
                </div>
              </div>
              <div className="how-it-work-page__list-item">
                <div className="how-it-work-page__image-container">
                  <img src="/images/step4.png" alt="" />
                </div>
                <div className="how-it-work-page__list-item-text">
                  <span className="how-it-work-page__list-item-step">{t('step')} 4</span>
                  <span dangerouslySetInnerHTML={{ __html: t('step4text') }} />
                </div>
              </div>
              <div className="how-it-work-page__list-item">
                <div className="how-it-work-page__image-container">
                  <img src="/images/step5.png" alt="" />
                </div>
                <div className="how-it-work-page__list-item-text">
                  <span className="how-it-work-page__list-item-step">{t('step')} 5</span>
                  <span dangerouslySetInnerHTML={{ __html: t('step5text') }} />
                </div>
              </div>
            </div>
          </div>
          {!AppStore.loggedIn && (
            <MediaQuery maxWidth={breakpoints['sm-max']}>
              <div className="pages-info-layout__buttons">
                <button onClick={goToLogin} className="main-button button-light icon">
                  {t('authorization.login')}
                  <SVG src={IconArrow} width={10} />
                </button>
                <button onClick={goToRegister} className="main-button  icon">
                  {t('authorization.register')}
                  <SVG src={IconPlus} width={13} />
                </button>
              </div>
            </MediaQuery>
          )}
        </div>
      </PageInfoLayout>
    </>
  );
};
export default HowItWorks;
