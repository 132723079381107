import { shortLinkApiInstance } from '../api';

export const createShortLink = async (url, siteId) => {
  try {
    const res = await shortLinkApiInstance().post('/short-urls', {
      source: url,
      target_site_id: siteId,
    });
    return res.data;
  } catch (e) {
    throw e;
  }
};
