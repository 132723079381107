import React from 'react';
import SVG from 'react-inlinesvg';
import { NavLink } from 'react-router-dom';
import Routes from '../../../routes';
import IconHeart from '../../../assets/icon/icon-heart-logo.svg';
import IconLogoEl from '../../../assets/icon/icon-logo-el.svg';
import AppStore from '../../../store/AppStore';

const Logo = ({ className }) => {
  return (
    <NavLink
      className={`logo ${className || ''}`}
      to={AppStore.loggedIn ? Routes.home : Routes.login}
    >
      <SVG src={IconHeart} width={24} />
      <div className="logo__text">
        Pay<span>Fan</span>
      </div>
      <SVG className="logo__icon-el" src={IconLogoEl} width={72} />
    </NavLink>
  );
};
export default Logo;
