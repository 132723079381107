import React from 'react';
import GetShortLinkButton from './GetShortLinkButton';

const ShopsListItemShop = ({ shop, shopId }) => {
  return (
    <div className="item-shop">
      <div className="item-shop__img">
        <img src={shop.src} alt="" />
      </div>
      <div className="item-shop__bottom">
        <div className="item-shop__name">{shop.name}</div>
        <GetShortLinkButton defaultLink={shop.link} siteId={shopId} />
      </div>
    </div>
  );
};
export default ShopsListItemShop;
