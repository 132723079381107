import React, { useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

const CountrySwitcher = ({ backgroundColor, singleValueColor, optionColor, colorArrow }) => {
  const { i18n } = useTranslation();
  const customStyles = {
    valueContainer: (provided) => ({
      ...provided,
      padding: '6px 0px 6px 10px',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),

    control: (provided) => ({
      ...provided,
      backgroundColor: backgroundColor || 'white',
      minHeight: '20px',
      padding: '0px',
      borderRadius: '50px',
    }),
    dropdownIndicator: (provided, state) => {
      return {
        ...provided,
        padding: state.isFocused ? '0px 5px' : '0px 5px',
        color: colorArrow || 'black',
      };
    },
    singleValue: (provided) => ({
      ...provided,
      color: singleValueColor || 'black',
    }),
    menuList: (provided) => ({
      ...provided,
      padding: '0',
    }),
    menu: (provided) => ({
      ...provided,
      background: 'none',
      border: 'none',
      boxShadow: 'none',
    }),
    option: (provided) => ({
      ...provided,
      background: 'white',
      borderRadius: '50px',
      marginBottom: '10px',
      cursor: 'pointer',
      color: optionColor || 'black',
      boxShadow: '0px 2px 5px rgb(45 60 88 / 20%)',
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      background: 'white',
      borderRadius: '50px',
      marginBottom: '10px',
      color: 'black',
      cursor: 'pointer',
    }),
  };
  const optionsLang = [
    { value: 'ru', label: 'Россия' },
    { value: 'en', label: 'USA' },
    { value: 'es', label: 'Español' },
  ];
  const localStorageLang = localStorage.getItem('i18nextLng').split('-') || 'en';
  document.querySelector('html').setAttribute('lang', localStorageLang);

  const initialLang = optionsLang.find((lang) => localStorageLang[0] === lang.value);

  const langListWithoutSelectedLang = optionsLang.filter((lang) => {
    return lang.value !== initialLang.value;
  });

  const [selectedOption, setSelectedOption] = useState(initialLang || optionsLang[0]);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    document.querySelector('html').setAttribute('lang', selectedOption?.value || 'en');
    i18n.changeLanguage(selectedOption.value);
  };
  return (
    <Select
      styles={customStyles}
      value={selectedOption}
      onChange={handleChange}
      options={langListWithoutSelectedLang}
      className="country-switcher"
      isSearchable={false}
    />
  );
};
export default CountrySwitcher;
