export default {
  translation: {
    authorization: {
      email: 'Correo electrónico',
      password: 'Contraseña',
      confirmPassword: 'Confirmación por contraseña',
      errorsEmptyInput: 'Por favor, rellena para continuar',
      noValidEmail: 'Formato de correo electrónico incorrecto',
      noValidPassword: 'La contraseña debe contener más de 3 símbolos.',
      coincidePassword: 'Las contraseñas deben coincidir',
      completeRegistration: 'Registro completo',
      login: 'Iniciar sesión',
      backToLogin: 'Volver a la página de inicio de sesión',
      register: 'Registro',
      forgotPassword: '¿Has olvidado la contraseña?',
      haveAccount: '¿Tienes una cuenta?',
    },
    menu: {
      home: 'Página principal',
      infoUser: 'Datos de usuario',
      profile: 'Perfil de usuario',
      statistics: 'Mis estadísticas',
      myBalance: 'Mi saldo',
      logOut: 'Cerrar sesión',
      products: 'Productos',
    },
    footer: {
      howWorks: 'Preguntas frecuentes',
      faq: 'Preguntas frecuentes',
      about: 'Acerca de la empresa',
      contact: 'Contactos',
      feedback: 'Contáctenos',
      dmca: 'DMCA',
      policy: 'Política de privacidad',
      terms: 'Acuerdo de usuario',
    },
    others: {
      notFound: 'Página no encontrada',
    },
    nicknameUser: 'Nombre de usuario',
    nickname: 'alias',
    fill: 'Rellenar',
    cannotEarn: '¡No podrás obtener beneficios hasta que se rellenen los datos de los usuarios!',
    acceptPolicy: 'Estoy de acuerdo con %privacy%.',
    saveChanges: 'Guardar los cambios',
    bestWeekOffers: 'Las mejores ofertas de esta semana',
    linkCopied: 'El enlace se ha copiado al portapapeles',
    getLink: 'Obtener un enlace',
    loadMore: 'Mostrar más',
    price: 'Precio',
    bonus: 'Bonificación',
    somethingWentWrong: 'Algo salió mal',
    needToFillNickname: 'Para ver la lista de productos, rellena el campo del alias',
    withdrawFunds: 'Retiro de fondos',
    availableForWithdrawal: 'Disponible para su retiro',
    withdraw: 'Retiro',
    withdrawalOfFounds: 'Retiro de fondos',
    withdrawalAmount: 'Importe del retiro',
    withdrawalRequests: 'Solicitudes de retiro',
    incorrectSum: 'El importe del retiro debe ser superior a 0 y menor que el importe del saldo',
    withdrawalSuccess: 'La solicitud de retiro ha sido creada con éxito',
    seeStatus: 'Comprobar el estado',
    date: 'Datos',
    sum: 'Importe del retiro',
    status: 'Estado',
    nothingFound: 'No se ha encontrado nada',
    active: 'Activo',
    approved: 'Aprobado',
    rejected: 'Cancelado',
    change: 'Cambios',
    balance: 'Saldo',
    changingYourBalance: 'Cambios en tu saldo',
    currency: 'Moneda',
    yourBalance: 'Tu saldo',
    lastName: 'Apellido',
    name: 'Nombre',
    surname: 'Patronímico',
    contactDetails: 'Datos de contacto',
    country: 'País',
    city: 'Ciudad',
    address: 'Dirección',
    phone: 'Número de teléfono',
    userProfile: 'Datos de usuario',
    profileImage: 'Imágenes del usuario',
    upload: 'Subir',
    userPassword: 'Contraseña de usuario',
    changePassword: 'Nueva contraseña',
    timeZone: 'Zona horaria',
    select: 'Seleccionar',
    selectPeriod: 'Seleccionar período',
    earnEvenMore: 'Obtén aún más beneficios',
    getUpTo: 'Obtén hasta el 60% del coste de los regalos de tus fans',
    tellMeMore: 'Cuéntame más',
    howEarnMore: '<b>Cómo</b> PayFan te ayuda a aumentar tus beneficios',
    ifHaveFans: 'Si tienes fans, podrás ganar <b>¡hasta el 60% del coste de sus regalos!</b>',
    toDoThis: 'Sólo tienes que seguir estos pasos:',
    step: 'Paso',
    step1text:
      'Completa un sencillo registro en nuestro sitio web y rellena tu perfil como se muestra en la captura de pantalla.',
    step2text: 'Selecciona el regalo que te guste, copia el enlace al regalo y envíalo a tu fan.',
    step3text:
      'Al realizar un pedido en el sitio web del socio, el fan te seleccionará como destinatario del regalo. <b>Tu fan no tendrá que introducir su dirección ni ningún otro dato para hacer un pedido. Así, ¡sus datos personales estarán seguros!</b>',
    step4text:
      'Recibirás inmediatamente un pago en tu cuenta personal, que será igual al 60% del coste del regalo. Después, ¡podrás retirar estos fondos a tu tarjeta!',
    step5text:
      '¡Así, recibirás un regalo y hasta el 60% de su coste acreditado en tu tarjeta bancaria!',
    faqTitle: '<b>PREGUNTAS FRECUENTES</b> y Soporte',
    backToMain: 'Volver a la página principal',
    faqPageContent:
      '<h2>¿Cómo funciona?</h2>\n' +
      '<div>¿Tus fans quieren hacer algo agradable para ti y hacerte un regalo, pero no quieres que conozcan tu dirección por cuestiones de seguridad? PayFan es una solución perfecta. Tu dirección estará siempre almacenada en el sistema. Envías a tu fan un enlace al regalo que has elegido, tu fan lo compra, el servicio de entrega lo entrega a la dirección que has especificado.</div>\n' +
      '<h2>¿Cuánto reembolso puedo obtener?</h2>\n' +
      '<div>Al principio, el reembolso estándar es del 10% del coste del regalo. El porcentaje aumentará a medida que crezcan las ventas.</div>\n' +
      '<h2>¿Qué productos de belleza vendes?</h2>\n' +
      '<div>Se trata de una nueva marca de cosméticos premium. Nuestros productos se fabrican en una zona no contaminada de Italia: sólo utilizamos materias primas respetuosas con el medio ambiente, controlamos todas las fases de la producción de los cosméticos y nunca probamos ni los ingredientes ni los productos preparados en animales. Trabajamos para hacer realidad tu deseo de cambiar de aspecto sin dejar de ser tú misma. Prestamos atención a lo que quieres de los cosméticos y pensamos en ti cuando trabajamos en nuevos productos.</div>\n' +
      '<h2>¿Qué simboliza tu logotipo?</h2>\n' +
      '<div>Es simplemente un corazón, simboliza el amor: el amor a nuestros clientes con el que creamos productos y el amor de los fans que deciden hacer un regalo.</div>',
    feedbackTitle: 'Formulario de <b>Comentarios</b>',
    feedbackSubTitle:
      '¡Si tienes alguna duda, no dudes en preguntarnos <b>en cualquier momento!</b>',
    fillContactForm: 'Sólo tienes que rellenar el formulario de contacto:',
    yourName: 'Tu nombre...',
    yourEmail: 'Tu correo electrónico...',
    howWeCanHelp: '¿Cómo podemos ayudarte?',
    submit: 'Enviar',
    privacyTitle: 'Política de <b>Privacidad<b>',
    privacyContent:
      '<h2>1. ¿Qué es la Política de Privacidad?</h2>\n' +
      '<p>La Política de Privacidad básica describe la relación entre el sitio web y la información personal de sus usuarios.</p>\n' +
      '<p>El primer paso para crear una política de privacidad compatible e integral es entender qué es la política de privacidad.</p>\n' +
      '<h3>Definición de la Política de Privacidad</h3>\n' +
      '<p>La Política de Privacidad es un documento legal que informa a los usuarios de tu sitio web sobre cómo recopilas y procesas su información personal. La política de privacidad también puede tener los siguientes nombres:</p>\n' +
      '<ul>\n' +
      '<li>Aviso de privacidad </li>\n' +
      '<li>Declaración de privacidad</li>\n' +
      '<li>Página de privacidad</li>\n' +
      '</ul>\n' +
      '<h3>Política de privacidad estándar para un sitio web</h3>\n' +
      '<p>Hablaremos de los detalles más adelante, en la sección <b classname="color">"Qué añadir a la política de privacidad de Boilerplate"</b>. La política de privacidad básica describe lo siguiente.</p>\n' +
      '<h2>2. ¿Es la Política de privacidad un requisito legal?</h2>\n' +
      '<p>Si tu sitio web utiliza información personal (por ejemplo, nombres, correo electrónico, información sobre una tarjeta bancaria), la mayoría de las legislaciones de todo el mundo te exigen que tengas una Política de Privacidad.</p>\n' +
      '<p>Si lanzas<b>una aplicación móvil o de escritorio o lanzas un sitio web</b>, muy probablemente, la legislación te obliga a tener una Política de Privacidad en tu sitio web. Debes proporcionar enlaces claros a tu Política de Privacidad en un lugar visible para que los usuarios puedan acceder a ella fácil y rápidamente.</p>',
    authorizationError: 'Error de autorización, por favor, inicia sesión de nuevo',
    avatarUploaded: 'Se ha subido la foto del perfil',
    delete: 'Eliminar',
    alreadyInUse: 'Este alias ya se utiliza',
    today: 'Hoy',
    week: 'Semana',
    month: 'Mes',
    forAllTime: 'Todo el tiempo',
    clearSelectedPeriod: 'Borrar el período seleccionado',
    confirm: 'Confirmar',
    loginError: 'Error al iniciar sesión: nombre de usuario o contraseña incorrectos',
    regError: 'El correo electrónico ya se utiliza',
    selectCountry: 'Selecciona el país',
    invalidPhone: 'Número de teléfono incorrecto',
    nicknameInvalid: 'Sólo puedes utilizar letras y dígitos latinos',
    productList: 'Lista de productos',
    payer: 'Pagador',
    dataChangedSuccess: 'Los datos han sido modificados con éxito',
    confirmAndContinue: 'Confirmar y continuar',
    cookieText:
      'Este sitio web utiliza archivos de cookies para mejorar tu experiencia de juego. Por favor, acepta todos los archivos de cookies o cambia la configuración de las mismas.',
    advanced: 'Configuración avanzada',
    acceptTheSelected: 'Aceptar los archivos de cookies seleccionados',
    cookieMore: 'Más detalles sobre los archivos de cookies',
    mandatory: 'Requerido',
    optionMandatory: 'Los archivos de cookies funcionales y necesarios deben estar activados',
    statistical: 'Estadística',
    optionStatistical:
      'Necesario para mejorar el funcionamiento del sitio web y la prestación de servicios',
    socialMedia: 'Red social',
    optionSocialMedia: 'Necesario para intercambiar contenidos en las redes sociales',
    productSubTitle:
      'Hola, belleza increíble! Elige el regalo que quieres recibir, copia un enlace y envíalo a tu fan. ¡Cuanto más compren tus fans, más beneficios obtendrás!',
    AboutUsTitle: 'Acerca de <b>nosotros</b>',
    AboutUsContent:
      '<p>\n' +
      'Tus fans quieren comprarte un regalo, pero no es seguro darles tu dirección... Tus fans no pueden elegir lo que realmente quieres... <br />\n' +
      'A menudo ocurre que varias personas quieren hacer el mismo regalo a alguien. ¿Estás seguro de que necesitas, por ejemplo, 5 lápices de labios iguales?\n' +
      '</p>\n' +
      '<p>\n' +
      'РayFan es una plataforma que permite no sólo recibir el regalo deseado de un fan,\n' +
      'sino también ganar dinero extra con ello.\n' +
      '<br />\n' +
      'PayFan es segura, rentable y cómoda.\n' +
      '</p>\n' +
      '<p>\n' +
      'Completa tu perfil en el sitio web, especifica la dirección de entrega y elige los regalos\n' +
      'de nuestros socios que te gusten. Cada regalo está provisto de su propio enlace único: ¡dáselo a tu fan y espera la entrega! No sólo obtendrás un regalo, sino también un porcentaje\n' +
      'de las ventas,\n' +
      'que se guardará en tu cuenta personal. Es un doble placer. Sólo el mensajero del servicio de entrega puede ver tu dirección. Esta persona no sabe quién eres\n' +
      'y de quién es el regalo que entrega.\n' +
      '</p>',
    latteShopInfo:
      '<p>\n' +
      'Se nos ocurrió la idea de crear LATTE BEAUTY en 2018 y lanzamos los primeros productos en 2019. Ahora, ofrecemos 70 productos y siempre nos aseguramos de que cada uno de ellos no sólo esté a la moda, sino que también sea de alta calidad y útil.\n' +
      '</p>\n' +
      '<p>\n' +
      'Sólo utilizamos materias primas respetuosas con el medio ambiente\n' +
      'y controlamos todas las etapas de la producción de cosméticos. La empresa se inspira en las tendencias de belleza actuales y, por supuesto, en tus comentarios para crear nuevos productos.\n' +
      'Como resultado,\n' +
      'recibes los cosméticos que satisfacen tus necesidades y proporcionan efectos rápidos y visibles, cuidando al mismo tiempo de tu salud.\n' +
      '</p>\n' +
      '<p>\n' +
      'No importa la edad que tengas, los mejores productos de belleza son los que resaltan tu belleza única y mejoran tu confianza en ti misma. Por eso nuestro catálogo siempre puede sorprenderte con algo nuevo e interesante, capaz de resaltar tus rasgos.\n' +
      '</p>\n' +
      '<p>\n' +
      'Nuestros productos se desarrollan en colaboración con los mejores expertos en cosmética,\n' +
      'son libres de crueldad, se fabrican con ingredientes de alta calidad y\n' +
      'con la ayuda del equipo de la empresa, y cumplen las normas GMP.\n' +
      '</p>',
    vittedyShopInfo:
      '<h3>VITEDDY</h3>\n' +
      '<p>\n' +
      'VITEDDY es transformación y cuidado diario, fórmulas únicas, tecnologías de vanguardia e ingredientes naturales para tu belleza.\n' +
      '</p>\n' +
      '<p>\n' +
      'El concepto de la marca VITEDDY se basa en la creación de soluciones cosméticas multifuncionales, eficaces y asequibles. La calidad y la funcionalidad, así como un efecto rápido y visible que hace que tu piel esté sana y bella y aporta felicidad a tu vida, son nuestros principales objetivos.\n' +
      '</p>\n' +
      '<p>\n' +
      'Nuestros expertos tienen muchos años de experiencia con los mejores equipos del mundo, por eso Viteddy puede utilizar tecnologías\n' +
      'y fórmulas únicas. Las pruebas en grupos de discusión ayudan a revelar las necesidades generales de las mujeres con diferentes tipos de piel y cabello y a seleccionar las fórmulas óptimas capaces de resolver varios problemas.\n' +
      '</p>\n' +
      '<p>\n' +
      'VITEDDY tiene su propio laboratorio de desarrollo y utiliza experiencia y tecnologías modernas para elaborar productos cosméticos. La cooperación con expertos tecnológicos independientes y profesionales internacionales de la industria de la belleza hace que nuestros productos no sólo sean modernos, sino también muy eficaces.\n' +
      '</p>\n' +
      '<p>\n' +
      'En noviembre de 2018, presentamos 4 productos cosméticos: así salieron a la venta los productos VITEDDY. En poco tiempo recibieron numerosos comentarios positivos y se convirtieron en un producto imprescindible para muchas blogueras de belleza y clientes populares.\n' +
      '</p>\n' +
      '<p>\n' +
      'La marca amplía constantemente la variedad de productos que ofrece. La línea completa de productos para el cuidado del cuero cabelludo y el cabello, desarrollada por un equipo de tricólogos y\n' +
      'estilistas, salió a la venta en la primavera de 2019. Se basa en las exigencias de la mujer moderna para el cuidado del cabello.\n' +
      '</p>\n' +
      '<p>\n' +
      'La empresa entra en nuevos mercados y establece asociaciones con los principales mercados. Puedes comprar nuestros productos en nuestro sitio web (con entrega en todo el mundo) y en las plataformas online, en los principales mercados, en los salones de belleza y a través de las redes de cosméticos.\n' +
      '</p>\n' +
      '<p>¿Qué hay ahí dentro? </p>\n' +
      '<p>\n' +
      'Las fórmulas de los cosméticos son una combinación de aceites nutritivos y vitaminas esenciales, gracias a los cuales tu pelo, tus uñas y tu piel se volverán muy pronto sedosos y brillantes. Brillarás con salud y belleza cada día.\n' +
      'Seguro que te enamoras de tu nuevo reflejo en el espejo.\n' +
      '</p>',
    partners: 'Socios',
    ourPartners: 'Nuestros Socios',
    officialDistributor: 'Distribuidor oficial',
    contactPhoneValue: '+356 203 41 583',
    contactAddressValue:
      "Darix Limited, Elite Business Centre, Trejqa ta' Box Box, Msida MSD 1840, Malta. Site: darix.com. 北京得乐贸易有限公司 中国 北京市朝阳区汤立路218号7层866 北京 直轄市 100012",
    contactDistributorValue:
      'Darix Beauty SL., <br/> PASEO DE GRACIA 1208007 BARCELONA, Spain; Global Sales Europe Ltd, Plovdiv 4000, St. Philip of Macedon 29, office 425, Bulgaria.',
    contactEmailValue: 'customerservice@lattebeauty.com',
    questionSent: 'Pregunta enviada con éxito',
  },
};
