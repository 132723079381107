export default {
  translation: {
    authorization: {
      email: 'Электронная почта',
      password: 'Пароль',
      confirmPassword: 'Подтверждение пароля',
      errorsEmptyInput: 'Пожалуйста, заполните, чтобы продолжить',
      noValidEmail: 'Неверный формат электронной почты',
      noValidPassword: 'Пароль должен содержать более 3 символов.',
      coincidePassword: 'Пароли должны совпадать',
      completeRegistration: 'Завершить регистрацию',
      login: 'Войти',
      backToLogin: 'Вернуться на страницу входа',
      register: 'Регистрация',
      forgotPassword: 'Забыли пароль?',
      haveAccount: 'У вас есть уже аккаунт?',
    },
    menu: {
      home: 'Главная',
      infoUser: 'Данные пользователя',
      profile: 'Профиль пользователя',
      statistics: 'Моя статистика',
      myBalance: 'Мой баланс',
      logOut: 'Выход',
      products: 'Продукты',
    },
    footer: {
      howWorks: 'Как это работает',
      faq: 'F.A.Q.',
      about: 'О компании',
      contact: 'Контакты',
      feedback: 'Обратная связь',
      dmca: 'DMCA',
      policy: 'Политика конфиденциальности',
      terms: 'Условия использования',
    },
    others: {
      notFound: 'Страница не найдено',
    },
    nicknameUser: 'Nickname Пользователя',
    nickname: 'nickname',
    fill: 'Заполнить',
    cannotEarn: 'Вы не можете зарабатывать, пока данные пользователя не будут заполнены!',
    acceptPolicy: 'Я соглашаюсь с %privacy%',
    saveChanges: 'Сохранить изменения',
    bestWeekOffers: 'Лучшие предложения недели',
    linkCopied: 'Ссылка скопирована в буфер обмена',
    getLink: 'Получить ссылку',
    loadMore: 'Показать еще',
    price: 'Цена',
    bonus: 'Бонус',
    somethingWentWrong: 'Что-то пошло не так',
    needToFillNickname: 'Для просмотра списка товаров необходимо заполнить поле никнейм',
    withdrawFunds: 'Вывести средства',
    availableForWithdrawal: 'Доступно для вывода',
    withdraw: 'Вывести',
    withdrawalOfFounds: 'Вывод средств',
    withdrawalAmount: 'Сумма вывода',
    withdrawalRequests: 'Заявки на вывод средств',
    incorrectSum: 'Сумма должна быть больше 0 и меньше суммы баланса',
    withdrawalSuccess: 'Заявка на вывод средств успешно создана',
    seeStatus: 'посмотреть статус',
    date: 'Дата',
    sum: 'Сумма',
    status: 'Статус',
    nothingFound: 'Ничего не найдено',
    active: 'Активная',
    approved: 'Одобрена',
    rejected: 'Отклонена',
    change: 'Изменение',
    balance: 'Баланс',
    changingYourBalance: 'Изменение вашего баланса',
    currency: 'Валюта',
    yourBalance: 'Ваш баланс',
    lastName: 'Фамилия',
    name: 'Имя',
    surname: 'Отчество',
    contactDetails: 'Контактные данные',
    country: 'Страна',
    city: 'Город',
    address: 'Адрес',
    phone: 'Телефон',
    userProfile: 'Данные пользователя',
    profileImage: 'Изображения пользователя',
    upload: 'Загрузить',
    userPassword: 'Пароль пользователя',
    changePassword: 'Сменить пароль',
    timeZone: 'Часовой пояс',
    select: 'Выбрать',
    selectPeriod: 'Выбрать период',
    earnEvenMore: 'Зарабатывайте еще больше',
    tellMeMore: 'Подробнее',
    getUpTo: 'Получите до 60% от стоимости подарков ваших поклонников',
    howEarnMore: '<b>Как</b> PayFan помогает вам зарабатывать больше',
    ifHaveFans:
      'Если у Вас есть поклонники, то Вы можете зарабатывать на их подарках <b>до 60% от их стоимости!</b>',
    toDoThis: 'Просто сделайте следующее:',
    step: 'Шаг',
    step1text:
      'Пройдите простую регистрацию у нас на сайте и заполните профиль, как показано на скриншоте.',
    step2text:
      'Выберите понравившийся подарок, скопируйте ссылку на подарок и отправьте ссылку своему поклоннику.',
    step3text:
      'Поклонник при заказе на сайте партнера выберет Вас в качестве получателя подарка. <b>Ему не нужно будет вводить Ваш адрес и другую информацию для оформления заказа. Таким образом Ваши личные данные будут в безопасности!</b>',
    step4text:
      'Вы моментально получите выплату в своем личном кабинете в размере до 60% от стоимости подарка. Далее Вы сможете вывести эту сумму на свою карту!',
    step5text: 'Таким образом Вы получите подарок и до 60% от его стоимости себе на карту!',
    faqTitle: '<b>F.A.Q.</b> и Поддержка',
    backToMain: 'Вернуться на главную',
    faqPageContent:
      '          <h2>Как это работает?</h2>\n' +
      '          <div>Ваши фанаты хотят сделать вам приятное и подарить подарок, но вы не хотите давать им свой адрес из-за соображений безопасности? PayFan – идеальное решение. Ваш адрес всегда остается внутри системы, Вы кидаете фанату ссылку на подарок, который выбрали, ваш фанат его подарок, а отправка осуществляется службой доставки по указанному вами адресу.</div>\n' +
      '          <h2>Сколько я могу получить в качестве кеш-бека?</h2>\n' +
      '          <div>На старте стандартный размер кеш-бека – 10% от суммы подарка. Далее процентная ставка растет вместе с количеством продаж.</div>\n' +
      '          <h2>Что за косметику вы продаете?</h2>\n' +
      '          <div>Это новый бренд премиальной косметики. Наше производство находится в экологически чистом районе в Италии - мы используем исключительно экологически чистое сырье, контролируем все этапы создания косметики и никогда не тестируем ни ингредиенты, ни готовые продукты на животных. Мы работаем, чтобы исполнить ваше желание быть яркой и разной, оставаясь собой. Мы внимательно относимся ко всем требованиям, которые вы предъявляете к косметике, и всегда думаем о вас, когда создаем очередной продукт.</div>\n' +
      '          <h2>Что означает ваш логотип?</h2>\n' +
      '          <div>Все просто - это сердце. И символизирует оно любовь. Нашу любовь к клиентам при создании продуктов и любовь фанатов, которые решают сделать подарок.</div>',
    feedbackTitle: 'Форма <b>обратной связи</b>',
    feedbackSubTitle:
      'Если возникнут какие-либо вопросы, не стесняйтесь обращаться к нам <b>в любое время!</b>',
    fillContactForm: 'Просто заполните контактную форму:',
    yourName: 'Ваше имя...',
    yourEmail: 'Ваш Email...',
    howWeCanHelp: 'Чем мы можем вам помочь?',
    submit: 'Отправить',
    privacyTitle: 'Политика <b>конфиденциальности</b>',
    privacyContent:
      '<h2>1. Что такое политика конфиденциальности?</h2>\n' +
      '          <p>Базовая политика конфиденциальности описывает отношения вашего веб-сайта с личной информацией пользователей.</p>\n' +
      '          <p>Первым шагом к созданию совместимой и всеобъемлющей политики конфиденциальности является точное понимание того, что это такое.</p>\n' +
      '          <h3>Определение политики конфиденциальности</h3>\n' +
      '          <p>Политика конфиденциальности — это юридический документ, который информирует пользователей вашего сайта о том, как вы собираете и обрабатываете их личную информацию. Вы также можете услышать, что политики конфиденциальности упоминаются под следующими именами:</p>\n' +
      '          <ul>\n' +
      '            <li>Уведомление о конфиденциальности</li>\n' +
      '            <li>Заявление о политике конфиденциальности</li>\n' +
      '            <li>Страница конфиденциальности</li>\n' +
      '          </ul>\n' +
      '          <h3>Стандартная политика конфиденциальности для веб-сайта</h3>\n' +
      '          <p>Мы углубимся в детали позже в разделе <b className="color">«Что включить в политику конфиденциальности Boilerplate»</b>, но основная политика конфиденциальности описывает следующее.</p>\n' +
      '          <h2>2. Требуется ли политика конфиденциальности по закону?</h2>\n' +
      '          <p>Если на вашем веб-сайте используется личная информация (например, собранные имена, адрес электронной почты или информация о кредитной карте), большинство законодательств во всем мире требуют, чтобы у вас была политика конфиденциальности.</p>\n' +
      '          <p>Если вы запускаете <b>веб-сайт, мобильное приложение или настольное приложение</b>, вы, вероятно, по закону обязаны иметь политику конфиденциальности где-то на своем сайте. Вы должны отображать ссылки на свою политику четко, на видном месте и на видном месте, чтобы пользователи могли быстро и легко перейти к ней.</p>',
    authorizationError: 'Ошибка авторизации, выполните вход повторно',
    avatarUploaded: 'Аватар загружен',
    delete: 'Удалить',
    alreadyInUse: 'Этот никнейм уже используется',
    today: 'Сегодня',
    week: 'Неделя',
    month: 'Месяц',
    forAllTime: 'За всё время',
    clearSelectedPeriod: 'Очистить выделенный период',
    confirm: 'Подтвердить',
    loginError: 'Ошибка входа: неверный логин или пароль',
    regError: 'Email уже используется',
    selectCountry: 'Выберите страну',
    invalidPhone: 'Не корректный номер телефона',
    nicknameInvalid: 'Можно использовать только латинские буквы и цифры',
    productList: 'Список товаров',
    payer: 'Плательщик',
    dataChangedSuccess: 'Данные успешно изменены',
    confirmAndContinue: 'Подтвердить и продолжить',
    cookieText:
      'Этот веб-сайт использует файлы cookie для улучшения вашего игрового опыта. Пожалуйста, примите все файлы cookie или измените настройки файлов cookie.',
    advanced: 'Расширенные опции',
    acceptTheSelected: 'Принять выбранные файлы cookie',
    cookieMore: 'Более подробная информация о файлах cookie',
    mandatory: 'Обязательные',
    optionMandatory: 'Функциональные и необходимые файлы cookie всегда должны быть включены',
    statistical: 'Статистические',
    optionStatistical: 'Необходимо для улучшения работы и услуг веб-сайта',
    socialMedia: 'Социальные сети',
    optionSocialMedia: 'Необходимо для обмена контентом в социальных сетях',
    productSubTitle:
      'Привет безумно красивая! Выберите подарок, который хотите получить, скопируйте ссылку и отправьте ее своему поклоннику. Чем больше он покупает, тем больше вы зарабатываете!',
    AboutUsTitle: 'О <b>нас</b>',
    AboutUsContent:
      '<p>\n' +
      '            Ваши поклонники хотят купить подарок, но давать свой адрес – небезопасно… Поклонники не\n' +
      '            могут подобрать именно то, что вы хотите… <br />\n' +
      '            Часто несколько человек хотят подарить одно и то же, но зачем вам, например, 5\n' +
      '            одинаковых помад?\n' +
      '          </p>\n' +
      '          <p>\n' +
      '            РayFan – это платформа, которая позволяет не только получить желанный подарок от\n' +
      '            поклонника, но при этом еще и дополнительно заработать.\n' +
      '            <br />\n' +
      '            PayFan – это безопасно, выгодно и удобно.\n' +
      '          </p>\n' +
      '          <p>\n' +
      '            Нужно заполнить профиль на сайте, указать адрес доставки и выбрать понравившиеся подарки\n' +
      '            от наших партнеров. У каждого подарка своя уникальная ссылка – передайте ее вашему\n' +
      '            поклоннику и ждите доставку! Вы получите не только подарок, но и проценты с продаж,\n' +
      '            которые будут копиться на вашем персональном счету. И это вдвойне приятно. При этом ваш\n' +
      '            адрес видит только курьер службы доставки, который не знает, ни кто вы, ни чей подарок\n' +
      '            он везет.\n' +
      '          </p>',
    latteShopInfo:
      '<h3>LatteBeauty</h3>\n' +
      '            <p>\n' +
      '              Идея создания бренда LATTE BEAUTY возникла в 2018 году, а первые продукты появились\n' +
      '              уже к 2020, сейчас в ассортименте более 70 наименований товаров. Компания заботится о\n' +
      '              том, чтобы каждый продукт в линейке был не просто актуальным и качественным, но и\n' +
      '              приносил реальную пользу.\n' +
      '            </p>\n' +
      '            <p>\n' +
      '              В производстве используется исключительно экологически чистое сырье и контролируются\n' +
      '              все этапы создания косметики. К разработке каждого продукта компания подходит,\n' +
      '              вдохновившись актуальными бьюти-тенденциями и, конечно, вашими отзывами. Результат —\n' +
      '              косметика, которая полностью отвечает вашим потребностям, которая дает быстрый видимый\n' +
      '              результат, одновременно заботясь о здоровье.\n' +
      '            </p>\n' +
      '            <p>\n' +
      '              В любом возрасте лучшая косметика – это та, что подчеркивает вашу уникальность и\n' +
      '              придает уверенности в себе. И потому в каталоге товаров вы всегда найдете что-то новое\n' +
      '              и интересное, что сможет вас удивить и подчеркнуть все достоинства вашей внешности.\n' +
      '            </p>\n' +
      '            <p>\n' +
      '              Продукция разработана в сотрудничестве с лучшими экспертами косметической отрасли,\n' +
      '              изготовлена ​​из высококачественных ингредиентов, не тестировалась на животных,\n' +
      '              производится на собственном оборудовании компании и в соответствии со стандартами GMP.\n' +
      '            </p>',
    vittedyShopInfo:
      '<h3>VITEDDY</h3>\n' +
      '            <p>\n' +
      '              VITEDDY - ежедневное преображение и уход, уникальные формулы, современные технологии и\n' +
      '              натуральные ингредиенты для вашей красоты.\n' +
      '            </p>\n' +
      '            <p>\n' +
      '              Создание многофункциональных, эффективных, качественных и доступных косметических\n' +
      '              средств – основная концепция бренда VITEDDY. Главная цель – качество косметических\n' +
      '              продуктов, их способность быть функциональными и быстрый и видимый эффект, делающий\n' +
      '              вашу кожу красивой и здоровой, а вас счастливее.\n' +
      '            </p>\n' +
      '            <p>\n' +
      '              Над созданием продукции работают специалисты с многолетним опытом работы на самом\n' +
      '              лучшем мировом оборудовании. Это позволяет Viteddy использовать уникальные технологии\n' +
      '              и формулы. Тестирование в фокус-группах помогает выявить общие потребности женщин с\n' +
      '              разным типом кожи и волос и подобрать оптимальные формулы, которые справятся с\n' +
      '              несколькими задачами.\n' +
      '            </p>\n' +
      '            <p>\n' +
      '              Cобственная лаборатория разработки VITEDDY использует современный опыт и технологии\n' +
      '              для создания косметических продуктов. Cотрудничество с независимыми техническими\n' +
      '              экспертами и международными профессионалами индустрии красоты делают продукты не\n' +
      '              только современными, но и очень эффективными.\n' +
      '            </p>\n' +
      '            <p>\n' +
      '              В ноябре 2018 года были представлены четыре косметических продукта, которые запустили\n' +
      '              продажи продукции VITEDDY. Продукты быстро получили огромное количество положительных\n' +
      '              отзывов и стали обязательными для многих популярных бьюти-блогеров и клиентов.\n' +
      '            </p>\n' +
      '            <p>\n' +
      '              Ассортимент продукции, предлагаемой брендом, постоянно расширяется. Полная линейка\n' +
      '              средств по уходу за кожей головы и волосами, разработанная командой трихологов и\n' +
      '              парикмахеров, выпущена весной 2019 года. Он основан на требованиях современной женщины\n' +
      '              к уходу за волосами.\n' +
      '            </p>\n' +
      '            <p>\n' +
      '              Глобальное присутствие и партнерство с крупными рынками постоянно расширяются. Помимо\n' +
      '              сайта, который предлагает возможность доставки по всему миру, вы можете купить\n' +
      '              продукцию на онлайн-площадках, крупных рынках, салонах красоты и через косметические\n' +
      '              сети.\n' +
      '            </p>\n' +
      '            <p>А что внутри? </p>\n' +
      '            <p>\n' +
      '              Формула косметики представляет собой сочетание питательных масел и незаменимых\n' +
      '              витаминов, благодаря которым ваши волосы, ногти и кожа за очень короткое время станут\n' +
      '              шелковистыми и сияющими. Вы будете сиять здоровьем и красотой каждый день. Обязательно\n' +
      '              влюбитесь в свое новое зеркальное отражение.\n' +
      '            </p>',
    partners: 'Партнеры',
    ourPartners: 'Наши партнеры. ',
    officialDistributor: 'Официальный дистрибьютор',
    contactPhoneValue: '+356 203 41 583',
    contactAddressValue: '129090, г.Москва, пер Протопоповский, дом 3',
    contactDistributorValue:
      'ООО “Дарикс” <br/>  ОГРН: 5077746891283 ИНН: 7706660548 КПП: 770801001',
    contactEmailValue: 'customerservice@lattebeauty.com',
    questionSent: 'Вопрос отправлен успешно',
  },
};
