import React from 'react';

const CookieOptionSwitcher = ({
  optionData,
  cookieOptionAcceptStatus,
  setCookieOptionAcceptStatus,
}) => {
  const switchHandleClick = () => {
    if (optionData.required) {
      return;
    }
    setCookieOptionAcceptStatus((prevState) => {
      return { ...prevState, [optionData.fieldName]: !prevState[optionData.fieldName] };
    });
  };
  return (
    <div className="cookie-option">
      <div className="cookie-option__inner">
        <div
          onClick={switchHandleClick}
          className={`cookie-option__switch ${
            cookieOptionAcceptStatus[optionData.fieldName] || optionData.required
              ? 'cookie-option__switch_active'
              : ''
          } ${optionData.required ? 'cookie-option__switch_required' : ''}`}
        >
          <span />
        </div>
        <div className="cookie-option__name">{optionData.name}</div>
      </div>
      <div className="cookie-option__text">{optionData.text}</div>
    </div>
  );
};

export default CookieOptionSwitcher;
