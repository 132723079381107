import React, { useState } from 'react';
import SVG from 'react-inlinesvg';
import IconArrow from '../../../assets/icon/icon-arrow-right.svg';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import Spinner from '../../../components/Base/Spinner';
import colorsVars from '../../../utils/colorsVars';

const FeedBackForm = () => {
  const { t } = useTranslation();

  const errorsValues = { name: '', email: '', question: '' };

  const [formValues, setFormValues] = useState(() => ({
    name: '',
    email: '',
    question: '',
  }));
  const [showLoader, setShowLoader] = useState(false);
  const [formErrors, setFormErrors] = useState(() => errorsValues);

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowLoader(true);

    const hasValidateErrors = validate(formValues);

    if (hasValidateErrors) {
      setShowLoader(false);
      return;
    }

    setTimeout(() => {
      setShowLoader(false);
      clearFormInput();
      toast.success(t('questionSent'));
    }, 2000);
  };

  const handleChange = (type, event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => {
      return { ...prevState, [name]: value };
    });
    setFormErrors((prevState) => {
      return { ...prevState, [name]: '' };
    });
  };

  const validate = (values) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const errorsValues = {};
    if (values.email === '') {
      errorsValues.email = t('authorization.errorsEmptyInput');
    } else if (!regex.test(values.email)) {
      errorsValues.email = t('authorization.errorsEmptyInput');
    }
    if (values.name === '') {
      errorsValues.name = t('authorization.errorsEmptyInput');
    }
    if (values.question === '') {
      errorsValues.question = t('authorization.errorsEmptyInput');
    }
    const hasValidateErrors = Object.keys(errorsValues).find((key) => errorsValues[key] !== '');

    setFormErrors((prevState) => {
      return { ...prevState, ...errorsValues };
    });

    return hasValidateErrors;
  };

  const classErrorForm = (error) => {
    return error !== '' ? 'no-valid-input' : '';
  };
  const clearFormInput = () => {
    setFormValues({
      name: '',
      email: '',
      question: '',
    });
  };

  return (
    <form className="feedback-form__form" onSubmit={handleSubmit}>
      <div className="feedback-form__form-title">{t('fillContactForm')}</div>
      <div className="feedback-form__form_wrapper-field">
        <div className="feedback-form__form-field">
          <label className="label feedback-form__form-label ">{t('yourName')}</label>
          <input
            type="text"
            name="name"
            value={formValues.name}
            onChange={(event) => handleChange('name', event)}
            className={`input feedback-form__form-input
                 ${classErrorForm(formErrors.name)}`}
          />
          {formErrors.name && <div className="input_error">{formErrors.name}</div>}
        </div>
        <div className="feedback-form__form-field">
          <label className="label feedback-form__form-label ">{t('yourEmail')}</label>
          <input
            type="text"
            name="email"
            value={formValues.email}
            onChange={(event) => handleChange('email', event)}
            className={`input feedback-form__form-input
                 ${classErrorForm(formErrors.email)}`}
          />
          {formErrors.email && <div className="input_error">{formErrors.email}</div>}
        </div>
        <div className="feedback-form__form-field">
          <label className="label feedback-form__form-label ">{t('howWeCanHelp')}</label>
          <textarea
            name="question"
            value={formValues.question}
            onChange={(event) => handleChange('name', event)}
            className={`textarea feedback-form__form-input
                 ${classErrorForm(formErrors.question)}`}
          />
          {formErrors.question && <div className="input_error">{formErrors.question}</div>}
        </div>
      </div>
      <div className="feedback-form__form_wrapper-button">
        <button
          type="submit"
          disabled={showLoader}
          className={`main-button  button-accent  ${showLoader ? 'main-button_loading' : ''}`}
        >
          <span>
            {t('submit')}
            <span className="icon-arrow-right">
              <SVG src={IconArrow} width={10} />
            </span>
          </span>
          {showLoader && (
            <div className="main-button__btn-loader">
              <Spinner width={18} height={18} borderWidth={2} color={colorsVars.colorLight} />
            </div>
          )}
        </button>
      </div>
    </form>
  );
};
export default observer(FeedBackForm);
