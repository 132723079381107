import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { observer } from 'mobx-react-lite';
import 'react-datepicker/dist/react-datepicker.css';
import { enUS, ru } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import IconChecked from '../../../assets/icon/icon-checked.svg';
import IconLoader from '../../../assets/icon/icon-loading.svg';
import IconClose from '../../../assets/icon/icon-close.svg';
import UserStore from '../../../store/UserStore';

const DatePickerMain = ({ handleDateSelect }) => {
  registerLocale('ru', ru);
  registerLocale('en', enUS);

  const { i18n, t } = useTranslation();
  const currentDate = new Date();

  const [language, setLanguage] = React.useState(i18n.language);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [activeFilterDate, setActiveFilterDate] = useState('');
  const [submitButtonLocked, setSubmitButtonLocked] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const handleSelectDate = () => {
    setSubmitButtonLocked(true);
    setShowLoader(true);
    handleDateSelect(startDate || null, endDate, activeFilterDate);
  };

  const handleDateSelectLocal = (date) => {
    UserStore.userBalanceLoad = false;
    const [start, end] = date;
    setStartDate(start);
    setEndDate(end);

    setActiveFilterDate('');

    setSubmitButtonLocked(false);
  };

  const filterDate = (e) => {
    UserStore.userBalanceLoad = false;
    const typeFilter = e.target.name;
    let end = new Date(endDate);
    if (!endDate) {
      setEndDate(currentDate);
      end = new Date(currentDate);
    }

    setSubmitButtonLocked(false);
    if (typeFilter === 'today') {
      setStartDate(currentDate);

      setEndDate(currentDate);

      setActiveFilterDate('today');
    } else if (typeFilter === 'week') {
      const start = end.setDate(end.getDate() - 6);
      setStartDate(start);

      setActiveFilterDate('week');
    } else if (typeFilter === 'month') {
      const start = end.setDate(end.getDate() - 30);
      setStartDate(start);

      setActiveFilterDate('month');
    } else {
      setStartDate(startDate || currentDate);

      setEndDate(endDate || currentDate);

      setActiveFilterDate('allDate');
    }
  };
  const clearDatePicker = () => {
    setStartDate(null);
    setEndDate(null);
    setSubmitButtonLocked(true);
    setActiveFilterDate('');
  };
  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);
  useEffect(() => {
    if (UserStore.userBalanceLoad) {
      setSubmitButtonLocked(false);
      setShowLoader(false);
    }
  }, [UserStore.userBalanceLoad]);

  return (
    <div className={`date-picker ${activeFilterDate === 'allDate' ? 'allDate' : ''}`}>
      <h2>{t('selectPeriod')}</h2>
      <div className="date-picker__button-wrapper">
        <button
          name="today"
          className={`date-picker__button ${activeFilterDate === 'today' ? 'active' : ''}`}
          onClick={(e) => filterDate(e)}
        >
          {t('today')}
        </button>
        <button
          name="week"
          className={`date-picker__button ${activeFilterDate === 'week' ? 'active' : ''}`}
          onClick={(e) => filterDate(e)}
        >
          {t('week')}
        </button>
        <button
          name="month"
          className={`date-picker__button ${activeFilterDate === 'month' ? 'active' : ''}`}
          onClick={(e) => filterDate(e)}
        >
          {t('month')}
        </button>
        <button
          name="allDate"
          className={`date-picker__button ${activeFilterDate === 'allDate' ? 'active' : ''}`}
          onClick={(e) => filterDate(e)}
        >
          {t('forAllTime')}
        </button>
      </div>
      <DatePicker
        locale={language}
        wrapperClassName="balance-page__date-picker"
        onChange={(date) => handleDateSelectLocal(date)}
        startDate={startDate}
        endDate={endDate}
        monthsShown={2}
        selectsRange
        withPortal
        isClearable
        inline
      />
      <div className="date-picker__button-bottom-wrapper">
        <button className="date-picker__button-clear" onClick={clearDatePicker}>
          <SVG src={IconClose} width={10} /> {t('clearSelectedPeriod')}
        </button>
        <button
          onClick={handleSelectDate}
          disabled={submitButtonLocked}
          className={`main-button  button-accent icon ${showLoader ? 'loading' : ''}`}
        >
          <SVG src={IconChecked} width={12} />
          {t('confirm')}
          <SVG className="loading" src={IconLoader} width={20} />
        </button>
      </div>
    </div>
  );
};
export default observer(DatePickerMain);
