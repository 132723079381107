import React, { useState } from 'react';
import Routes from '../../routes';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';
import CountrySwitcher from '../CountrySwitcher';
import SVG from 'react-inlinesvg';
import IconArrow from '../../assets/icon/icon-arrow-right.svg';
import IconAvailableBalance from '../../assets/icon/icon-available-balance.svg';
import MediaQuery from 'react-responsive';
import breakpoints from '../../utils/breakpoints';
import UserStore from '../../store/UserStore';
import { useTranslation } from 'react-i18next';
import Modal from 'react-responsive-modal';
import WithdrawModal from '../Withdraw';

const HeaderTop = () => {
  const { t } = useTranslation();
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const fullName = `${UserStore.user.firstName} ${UserStore.user.lastName}`;
  const avatarSrc = UserStore.user.avatar ? UserStore.user.avatar : '/images/avatar.png';
  const openWithdrawModal = () => {
    setShowWithdrawModal(true);
  };

  const closeWithdrawModal = () => {
    setShowWithdrawModal(false);
  };
  return (
    <div className="header-top">
      <MediaQuery minWidth={breakpoints['lg-min']}>
        <CountrySwitcher backgroundColor="#00aff0" singleValueColor="#fff" colorArrow="#fff" />
      </MediaQuery>
      <div className="header-top__info">
        <NavLink
          className="header-top__user-info"
          exact
          activeClassName="header-top__link_active"
          to={Routes.profile}
        >
          <div className="header-top__user-wrapper">
            <div className="header-top__user-avatar">
              <img src={avatarSrc} alt="" />
            </div>
            {fullName && <div className="header-top__user-name">{fullName}</div>}
          </div>
        </NavLink>
        <div className="header-top__balance">
          <SVG className="header-top__balance-icon" src={IconAvailableBalance} width={14} />
          {t('availableForWithdrawal')}:
          <span>
            {' '}
            {UserStore.user.balance} {UserStore.user.currency}
            <MediaQuery maxWidth={breakpoints['xs-max']}>
              <b onClick={openWithdrawModal}>
                {t('withdraw')} <SVG src={IconArrow} width={6} />
              </b>
            </MediaQuery>
          </span>
        </div>
        <MediaQuery minWidth={breakpoints['xs-max']}>
          <button onClick={openWithdrawModal} className="header-top__button main-button icon">
            {t('withdrawFunds')}
            <SVG src={IconArrow} width={13} />
          </button>
        </MediaQuery>
        <Modal
          center
          onClose={closeWithdrawModal}
          open={showWithdrawModal}
          classNames={{ modal: 'withdraw-funds__modal' }}
        >
          <WithdrawModal closeModal={closeWithdrawModal} />
        </Modal>
      </div>
    </div>
  );
};
export default observer(HeaderTop);
